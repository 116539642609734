import React, { useContext } from 'react';
import styled from 'styled-components';

import { AppContext } from 'context';

const StyledGridLayout = styled.div`
  display: grid;
  grid-template-columns: var(${props => props.$open ? "--mx-sidebar-width" : "--mx-sidebar-collapsed-width"}) auto;
  grid-template-rows: var(--mx-navmenu-height) auto var(--mx-footer-height);
  grid-template-areas:
    "nav nav nav"
    "sidebar page page"
    "sidebar footer footer";

  height: 100vh;
  transition: all 0.3s ease-in-out;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-template-rows: var(--mx-navmenu-height) auto auto var(--mx-footer-height);
    grid-template-areas:
      "nav"
      "sidebar"
      "page"
      "footer";
  }
`;

export function GridLayout({ children }) {
  const context = useContext(AppContext);
  return (
    <StyledGridLayout
      className={context.profile.lightTheme ? "sl-theme-light" : "sl-theme-dark"}
      $open={context.profile.sidebarOpen}>
      {children}
    </StyledGridLayout>
  )
}
