/* eslint-disable no-unused-vars */
import { Page, PageHeader, PageBody, Title, Toolbar, ToolbarItem, FlexCols, FlexRows, FlexItem } from "lib/core/flex"
import PannelSmall from "lib/components/panel-sm";
import { styled, css } from 'styled-components';
import React, { useEffect, useState } from 'react';

import SlDetails from '@shoelace-style/shoelace/dist/react/details';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
// import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
// import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
// import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';
// import SlTag from '@shoelace-style/shoelace/dist/react/tag';
import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import { StyledTabs } from "lib/components/tabs";
import { TableView } from "lib/components/table";
import { NoData, DataSafeComponent } from 'routes/main'

import { useAppStore, wrapPromise } from 'stores/appStore';
import useSessionData from "stores/useSessionData";

const Panel = styled.div`
  overflow-y: auto;
`

const StyledTableFooter = styled.div`
  // border: 1px solid red;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledPagination = styled.div`
  margin-right: var(--sl-spacing-x-small);
`
const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex-flow: row wrap;
  flex: 1;
  overflow-y: scroll;
  border-right: 1px solid ${({ theme }) => theme.color.border};
`;

const StyledMenuItem = styled.a`
  padding: var(--mx-navmenu-item-padding);
  border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.background};;
  text-decoration: none;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s linear;
  height: 54px;

  border-bottom: 1px dotted ${({ theme }) => theme.color.border};

  &:hover {
    background-color: ${({ theme }) => theme.color.hoverBackground};
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.hoverBackground};
  }

  ${props => props.$current && css`
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
    &:hover {
      border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
    }
  `}

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
`;

const ListItem = styled(FlexCols)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;
  background-color: ${({ theme }) => theme.color.background};

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  }
`

const InnerPanel = styled(FlexCols).attrs({
  $bd: true,
  $jcsb: true,
  $pd5: true,
})`
  height: 66.5vh;
  border-radius: 0px;
  border-left: none;
  padding: 15px 10px 5px 20px;
  background-color: ${({ theme }) => theme.color.pannelInner};
`

const asStats1 = [
  {
    title: "Domains",
    stat: "12"
  }, {
    title: "Subdomains",
    stat: "72"
  }, {
    title: "IPs",
    stat: "96"
  }, {
    title: "Open Ports",
    stat: "15"
  }, {
    title: "Expired Certificates",
    stat: "7"
  }, {
    title: "Vulnerabilities",
    stat: "821"
  }
];

const workspaceData = [
  {
    "id": "ws-blogs",
    "host": "acme.group",
    "range": 235,
    "assets": [
      {
        "name": "asset-w11"
      }, {
        "name": "asset-w12"
      }, {
        "name": "asset-w13"
      }, {
        "name": "asset-w14"
      }, {
        "name": "asset-w15"
      },
    ]
  }, {
    "id": "ws-customer support",
    "host": "acme.directory",
    "range": 245,
    "assets": [
      {
        "name": "asset-w21"
      }, {
        "name": "asset-w22"
      }, {
        "name": "asset-w23"
      },
    ]
  }, {
    "id": "ws-admin dev"
  }, {
    "id": "ws-admin prod"
  }, {
    "id": "ws-admin stage"
  }, {
    "id": "ws-productions"
  }, {
    "id": "ws-app group-a"
  }, {
    "id": "ws-app group-b"
  }, {
    "id": "ws-service"
  }, {
    "id": "ws-promo"
  }, {
    "id": "ws-sales"
  }, {
    "id": "ws-retails"
  }
];

const domainData = [
  {
    "id": "acme.group",
    "host": "acme.group",
    "range": 235,
    "assets": [
      {
        "name": "asset-d11"
      }, {
        "name": "asset-d12"
      }, {
        "name": "asset-d13"
      },
    ]
  }, {
    "id": "acme.directory",
    "host": "acme.directory",
    "range": 245,
    "assets": [
      {
        "name": "asset-d21"
      }, {
        "name": "asset-d22"
      }, {
        "name": "asset-d23"
      },
    ]
  }, {
    "id": "acme.today",
    "hostid": "acme.today"
  }, {
    "id": "acme.international",
    "host": "acme.international"
  }, {
    "id": "acmeonline.com",
    "host": "acmeonline.com"
  }, {
    "id": "acme.asia",
    "host": "acme.asia"
  }, {
    "id": "acme.company",
    "host": "acme.company"
  }, {
    "id": "solarnorthwind.com",
    "host": "solarnorthwind.com"
  }, {
    "id": "solarwestwind.com",
    "host": "solarwestwind.com"
  }, {
    "id": "acme.green",
    "host": "acme.green"
  }, {
    "id": "acme.expert",
    "host": "acme.expert"
  }, {
    "id": "acme.center",
    "host": "acme.center"
  }
];

const Label = styled.h4``
const Text = styled.span``

function ToolbarItems(props) {
  return (
    <ToolbarItem>
      <SlButtonGroup label="Views">
        <SlTooltip content="Group by workspace">
          <SlButton size="medium" onClick={() => props.groupBy(1)}>
            <SlIcon name="briefcase"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="Group by domain">
          <SlButton size="medium" onClick={() => props.groupBy(2)}>
            <SlIcon name="globe"></SlIcon>
          </SlButton>
        </SlTooltip>
      </SlButtonGroup>
    </ToolbarItem>
  )
}

function ToolbarFilter() {
  return (
    <SlDetails summary="Filter">
      <FlexCols $g5>
        <FlexRows $g15>
          <FlexItem $fx="1"><small>IP Address</small></FlexItem>
          <FlexItem $fx="1"><small>Hostname</small></FlexItem>
          <FlexItem $fx="1"><small>Ports</small></FlexItem>
          <FlexItem $fx="1"><small>Protocol</small></FlexItem>
          <FlexItem $fx="1"><small>OS</small></FlexItem>
          <FlexItem $fx="1"><small>Service</small></FlexItem>
        </FlexRows>
        <FlexRows $g15>
          <FlexItem $fx="1"><SlInput size="small" clearable style={{ width: "200px" }} /></FlexItem>
          <FlexItem $fx="1"><SlInput size="small" clearable style={{ width: "200px" }} /></FlexItem>
          <FlexItem $fx="1"><SlInput size="small" clearable style={{ width: "200px" }} /></FlexItem>
          <FlexItem $fx="1"><SlInput size="small" clearable style={{ width: "200px" }} /></FlexItem>
          <FlexItem $fx="1"><SlInput size="small" clearable style={{ width: "200px" }} /></FlexItem>
          <FlexItem $fx="1"><SlInput size="small" clearable style={{ width: "200px" }} /></FlexItem>
        </FlexRows>
      </FlexCols>
    </SlDetails>
  )
}

function TopPanel(props) {
  var args = {
    slice_name: "asset2",
    store_name: "overview"
  }

  var resource = useSessionData(args)

  var args2 = {
    slice_name: "stat",
    store_name: "severity"
  }

  var resource2 = useSessionData(args2)
  var findings = resource2 ? (resource2?.data?.critical + resource2?.data?.high + resource2?.data?.medium + resource2?.data?.low) : 0;
  var stats = resource ? [
    {
      title: "Domains",
      stat: resource?.data?.domains
    }, {
      title: "Subdomains",
      stat: resource?.data?.sub_domains
    }, {
      title: "IPs",
      stat: resource?.data?.ips
    }, {
      title: "Open Ports",
      stat: resource?.data?.ports
    }, {
      title: "Expired Certificates",
      stat: resource?.data?.expired_certs
    }, {
      title: "Findings",
      stat: findings
    }
  ] : []

  return (
    <>
      {
        resource && (
          <PannelSmall data={stats}></PannelSmall>
        )
      }
    </>
  )
}

function LeftPanel(props) {
  var args = {
    slice_name: "asset2",
    store_name: "workspaces"
  }

  var resource = useSessionData(args)

  if (props?.selected.get === null && resource?.data) {
    props?.selected.set(resource?.data[0].id)
  }

  function isSelected(value) {
    return props?.selected.get === value;
  }

  function clickHandler(e, target) {
    e.preventDefault();
    props?.selected.set(target);
  }

  return (
    <>
      {
        resource && (
          <StyledMenuContainer>
            {resource?.data?.map((item) => (
              <StyledMenuItem key={item.id} $current={isSelected(item.id)} onClick={(e) => clickHandler(e, item.id)}>{item.name}</StyledMenuItem>
            ))}
          </StyledMenuContainer>
        )
      }
    </>
  )
}

// function RightPanel(props) {
//   function handleItemClick() {
//     props.setOpen(true)
//   }

//   return (
//     <InnerPanel>
//       <FlexCols $g10 style={{ overflowY: "scroll", margin: "-10px -5px 10px -15px", padding: "10px 15px 10px 15px" }}>
//         {props.data.filter(d => d.id === props.selected)[0]?.assets?.map((asset, i) => (
//           <ListItem $bd $pd15 $g10 key={i} onClick={() => handleItemClick()}>
//             <FlexRows>
//               <FlexItem $fx="1"><small>IP Address</small></FlexItem>
//               <FlexItem $fx="1"><small>Hostname</small></FlexItem>
//               <FlexItem $fx="0.5"><small>Vulnerabilities</small></FlexItem>
//               <FlexItem $fx="0.5"><small>Ports</small></FlexItem>
//               <FlexItem $fx="0.5"><small>Certificates</small></FlexItem>
//             </FlexRows>
//             <FlexRows>
//               <FlexItem $fx="1"><SlTag variant="neutral" size="small">72.115.212.{props.data.filter(d => d.id === props.selected)[0].range + i}</SlTag></FlexItem>
//               <FlexItem $fx="1"><SlTag variant="neutral" size="small">{"srv" + (i+1) + "." + props.data.filter(d => d.id === props.selected)[0].host}</SlTag></FlexItem>
//               <FlexItem $fx="0.5"><SlTag variant="neutral" size="small">{4 + i*3 + i}</SlTag></FlexItem>
//               <FlexItem $fx="0.5"><SlTag variant="neutral" size="small">3</SlTag></FlexItem>
//               <FlexItem $fx="0.5"><SlTag variant="neutral" size="small">2</SlTag></FlexItem>
//             </FlexRows>
//             <FlexRows>
//               <FlexItem $fx="2"><small>First seen: 04-Apr-2024</small>&nbsp;&nbsp;|&nbsp;&nbsp;<small>Last seen: 04-Apr-2024</small></FlexItem>
//               <FlexItem $fx="1.5"><small style={{ color: "#909090" }}>Critical: 2 | High: {i} | Medium: {2 + i} | Low: {2*i}</small></FlexItem>
//             </FlexRows>
//           </ListItem>
//         ))}
//       </FlexCols>
//       <FlexCols>
//         <SlDivider style={{ marginRight: '10px' }} />
//         <StyledTableFooter>
//           <div>
//             <SlDropdown>
//               <SlButton slot="trigger" caret>
//                 5
//               </SlButton>
//               <SlMenu>
//                 <SlMenuItem value="rpp-10">5</SlMenuItem>
//                 <SlMenuItem value="rpp-15">10</SlMenuItem>
//                 <SlMenuItem value="rpp-20">15</SlMenuItem>
//               </SlMenu>
//             </SlDropdown>&nbsp;&nbsp;&nbsp;<span>Items per page</span>
//           </div>
//           <StyledPagination>
//             <SlDropdown>
//               <SlButton slot="trigger" caret>
//                 1
//               </SlButton>
//               <SlMenu>
//                 <SlMenuItem value="rpp-10">1</SlMenuItem>
//                 <SlMenuItem value="rpp-15">2</SlMenuItem>
//                 <SlMenuItem value="rpp-20">3</SlMenuItem>
//               </SlMenu>
//             </SlDropdown>
//             &nbsp;&nbsp;&nbsp;
//             <SlButtonGroup label="Pagination">
//               <SlTooltip content="Prev">
//                 <SlButton size="medium">
//                   <SlIcon name="arrow-left"></SlIcon>
//                 </SlButton>
//               </SlTooltip>
//               <SlTooltip content="Next">
//                 <SlButton size="medium">
//                   <SlIcon name="arrow-right"></SlIcon>
//                 </SlButton>
//               </SlTooltip>
//             </SlButtonGroup>
//           </StyledPagination>
//         </StyledTableFooter>
//       </FlexCols>
//     </InnerPanel>
//   )
// }

function CxPanelIPs(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getScanAssetIPs)
  const [resource, setResource] = useState(null)
  const [rowItems, setRowItems] = useState(null)

  const columns = [
    {
      field: "ip",
      display: "IP Addresses"
    }
  ];

  const options = {
    checkbox: false,
    search: false
  }

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  useEffect(() => {
    var rowItems = { rows: [] }
    resource?.data?.ips?.forEach((item) => {
      rowItems.rows.push({ "ip": item })
    })
    setRowItems(rowItems)
  }, [resource])

  // return (
  //   <TableView data={{ columns, rows: rowItems.rows }} options={options} />
  // )
  return (
    rowItems && (
      rowItems?.rows?.length > 0 ?
        (<TableView data={{ columns, rows: rowItems.rows }} options={options} />)
        : <NoData />
    )
  )
}

function CxPanelCidr(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getScanAssetCIDR)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  return (
    <div>
      {resource?.data?.cidr}
    </div>
  )
}

function CxPanelAsn(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getScanAssetASN)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  return (
    <div>
      asn: {resource?.data?.asn}
    </div>
  )
}

function CxPanelSubdomains(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getSubdomains)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  return (
    <div>
      {
        resource?.data?.length ?
          (
            <>
              <CxPanelSubdomainVulnerabilities wid={props.wid} sid={props.sid} sdid={resource?.data[0]} />
              <CxPanelSubdomainWeb wid={props.wid} sid={props.sid} sdid={resource?.data[0]} />
              <CxPanelSubdomainOSINT wid={props.wid} sid={props.sid} sdid={resource?.data[0]} />
              <CxPanelSubdomainNMAP wid={props.wid} sid={props.sid} sdid={resource?.data[0]} />
            </>
          ) : <></>
      }
    </div>
  )
}

function CxPanelSubdomainVulnerabilities(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getSubdomainVulnerabilities)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined && props.sdid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid), "sdid": String(props.sdid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid, props.sdid]);

  return (
    <div>
      {
        resource?.data?.items?.map((item, i) => (
          <div key={i}>
            {item.title}, {item.severity}
          </div>
        ))
      }
    </div>
  )
}

function CxPanelSubdomainWeb(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getSubdomainWeb)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined && props.sdid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid), "sdid": String(props.sdid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  return (
    <div>
      {/* {resource?.data} */}
    </div>
  )
}

function CxPanelSubdomainOSINT(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getSubdomainOSINT)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined && props.sdid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid), "sdid": String(props.sdid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  return (
    <div>
      {/* {resource?.data} */}
    </div>
  )
}

function CxPanelSubdomainNMAP(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getSubdomainNMAP)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.wid != undefined && props.sid != undefined && props.sdid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid), "sid": String(props.sid), "sdid": String(props.sdid) } }
      setResource(wrapPromise(getData(args)));
    }
  }, [props.wid, props.sid]);

  return (
    <div>
      {/* {resource?.data} */}
    </div>
  )
}

function RightPanel(props) {
  const store = useAppStore((state) => state.asset)
  const getData = useAppStore((state) => state.getLatestScan)
  const [resource, setResource] = useState(null)
  const items = [
    {
      id: "subdomains",
      name: "Subdomains",
      children: <CxPanelSubdomains wid={props.wid} sid={resource?.data?.latest_scan} />
    },
    {
      id: "ips",
      name: "IPs",
      children: (
        <DataSafeComponent>
          <CxPanelIPs wid={props.wid} sid={resource?.data?.latest_scan} />
        </DataSafeComponent>
      )
    },
    {
      id: "cidr",
      name: "CIDR",
      children: <CxPanelCidr wid={props.wid} sid={resource?.data?.latest_scan} />
    },
    {
      id: "asn",
      name: "ASN",
      children: <CxPanelAsn wid={props.wid} sid={resource?.data?.latest_scan} />
    }
  ];

  useEffect(() => {
    if (props.wid != undefined) {
      const args = { "force": true, "path": { "wid": String(props.wid) } }
      setResource(wrapPromise(getData(args)));
    }

  }, [props.wid]);

  return (
    <InnerPanel>
      <StyledTabs items={items} />
    </InnerPanel>
  )
}

function AttackSurfaceTable() {
  return (
    <>
      <FlexRows>
        <FlexItem $fx="0.5"><Label>Port</Label></FlexItem>
        <FlexItem $fx="0.5"><Label>Protocol</Label></FlexItem>
        <FlexItem $fx="1"><Label>Url</Label></FlexItem>
        <FlexItem $fx="1"><Label>Technology</Label></FlexItem>
      </FlexRows>
      <SlDivider />
      <FlexRows>
        <FlexItem $fx="0.5"><Text>80/tcp</Text></FlexItem>
        <FlexItem $fx="0.5"><Text>http</Text></FlexItem>
        <FlexItem $fx="1"><Text>http://srv1.acme.group</Text></FlexItem>
        <FlexItem $fx="1"><Text>---</Text></FlexItem>
      </FlexRows>
      <FlexRows>
        <FlexItem $fx="0.5"><Text>443/tcp</Text></FlexItem>
        <FlexItem $fx="0.5"><Text>https</Text></FlexItem>
        <FlexItem $fx="1"><Text>https://srv1.acme.group</Text></FlexItem>
        <FlexItem $fx="1"><Text>---</Text></FlexItem>
      </FlexRows>
      <FlexRows>
        <FlexItem $fx="0.5"><Text>8080/tcp</Text></FlexItem>
        <FlexItem $fx="0.5"><Text>https</Text></FlexItem>
        <FlexItem $fx="1"><Text>https://srv1.acme.group:8080</Text></FlexItem>
        <FlexItem $fx="1"><Text>---</Text></FlexItem>
      </FlexRows>
    </>
  )
}

function AttackSurfaceDetails() {
  return (
    <>
      <FlexCols $g10>
        <FlexRows>
          <FlexItem $fx="1"><Label>Risk Score</Label></FlexItem>
          <FlexItem $fx="1"><Label>IP Address</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>34%</Text></FlexItem>
          <FlexItem $fx="1"><Text>72.115.212.235</Text></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Label>Host</Label></FlexItem>
          <FlexItem $fx="1"><Label>Record Type</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>srv1.acme.group</Text></FlexItem>
          <FlexItem $fx="1"><Text>---</Text></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Label>Hosting</Label></FlexItem>
          <FlexItem $fx="1"><Label>Location</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>---</Text></FlexItem>
          <FlexItem $fx="1"><Text>---</Text></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Label>First seen</Label></FlexItem>
          <FlexItem $fx="1"><Label>Last seen</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>04-Apr-2024</Text></FlexItem>
          <FlexItem $fx="1"><Text>04-Apr-2024</Text></FlexItem>
        </FlexRows>
        <div style={{ height: "10px" }}></div>
        <SlDivider />
        <FlexRows>
          <FlexItem $fx="1"><Label>Vulnerabilities</Label></FlexItem>
          <FlexItem $fx="1"><Label>Verified</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>4</Text></FlexItem>
          <FlexItem $fx="1"><Text>2</Text></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Label>Critical</Label></FlexItem>
          <FlexItem $fx="1"><Label>High</Label></FlexItem>
          <FlexItem $fx="1"><Label>Medium</Label></FlexItem>
          <FlexItem $fx="1"><Label>Low</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>2</Text></FlexItem>
          <FlexItem $fx="1"><Text>0</Text></FlexItem>
          <FlexItem $fx="1"><Text>2</Text></FlexItem>
          <FlexItem $fx="1"><Text>0</Text></FlexItem>
        </FlexRows>
        <div style={{ height: "10px" }}></div>
        <SlDivider />
        <FlexRows>
          <FlexItem $fx="1"><Label>Open Ports</Label></FlexItem>
          <FlexItem $fx="1"><Label>Expired Certificates</Label></FlexItem>
        </FlexRows>
        <FlexRows>
          <FlexItem $fx="1"><Text>3</Text></FlexItem>
          <FlexItem $fx="1"><Text>2</Text></FlexItem>
        </FlexRows>
        <div style={{ height: "10px" }}></div>
        <SlDivider />
        <AttackSurfaceTable />
        <SlDivider />
      </FlexCols>
    </>
  )
}

export default function AttackSurface2() {
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState(workspaceData);
  const [selected, setSelected] = useState(null);

  // useEffect(() => {
  //   setSelected(data[0].id)
  // }, [data]);

  function handleGroupChange(groupId) {
    // setData(groupId == 2 ? domainData : workspaceData)
  }

  return (
    <Page>
      <PageHeader>
        <Title>Attack Surface</Title>
        <Toolbar><ToolbarItems groupBy={handleGroupChange} /></Toolbar>
      </PageHeader>
      <PageBody $m15>
        <FlexCols $g15>
          <DataSafeComponent><TopPanel /></DataSafeComponent>
          <Panel>
            <FlexCols $g10>
              {/* <ToolbarFilter /> */}
              <FlexRows>
                <DataSafeComponent>
                  <FlexItem $fx="1">
                    <FlexCols $jcsb $bd style={{ height: "66.5vh", borderRadius: "0px", borderRight: "none" }}>
                      <LeftPanel selected={{ get: selected, set: setSelected }} />
                    </FlexCols>
                  </FlexItem>
                  <FlexItem $fx="3">
                    <DataSafeComponent>
                      <RightPanel wid={selected} setOpen={setOpen} />
                    </DataSafeComponent>
                  </FlexItem>
                </DataSafeComponent>
              </FlexRows>
            </FlexCols>
          </Panel>
        </FlexCols>
        <SlDrawer label="Attack Surface Details" open={open} onSlAfterHide={() => setOpen(false)} style={{ '--size': '40vw' }}>
          <AttackSurfaceDetails />
          <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
            Close
          </SlButton>
        </SlDrawer>
      </PageBody>
    </Page>
  )
}