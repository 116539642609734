import PannelSmall from 'lib/components/panel-sm';
import { FindingsCount } from './findingsCount';

export const TopPanel = (props) => {
  var stats = [
    {
      title: "Domains",
      stat: props?.asset?.domains
    }, {
      title: "Subdomains",
      stat: props?.asset?.sub_domains
    }, {
      title: "IPs",
      stat: props?.asset?.ips
    }, {
      title: "Open Ports",
      stat: props?.asset?.ports
    }, {
      title: "Expired Certificates",
      stat: props?.asset?.expired_certs
    }, {
      title: "Findings",
      stat: <FindingsCount severity={props.severity} />
    }
  ]

  return (
    <>
      {
        props.asset && (
          <PannelSmall data={stats}></PannelSmall>
        )
      }
    </>
  )
}