// import styled from 'styled-components';
// import { useState } from 'react';
// import SlButton from '@shoelace-style/shoelace/dist/react/button';
// import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
// import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
// import SlInput from '@shoelace-style/shoelace/dist/react/input';
// import SlCopyButton from '@shoelace-style/shoelace/dist/react/copy-button';

// import { TableView } from "lib/components/table";

import styled from 'styled-components';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 10px;
`

const StyledItems = styled.div`
  width: calc((95vw - var(--mx-sidebar-width) - 200px)/3);
  height: 160px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 4px;
  padding: 20px;
  // font-size: var(--sl-font-size-medium);
`

// const columns = [
//   {
//     field: "name",
//     display: "Name"
//   },
//   {
//     field: "apikey",
//     display: "API Key"
//   },
//   {
//     field: "created",
//     display: "Created"
//   }
// ];

// const rows = [
//   {
//     name: "API Key-01",
//     apikey: <span>ca4-xxx-460<SlCopyButton value="ca4ea527-ed49-4a52-8d08-748ccebdb460" /></span>,
//     created: "2024-04-04 10:10"
//   },
//   {
//     name: "API Key-02",
//     apikey: <span>3d4-xxx-6d2<SlCopyButton value="3d436c4d-9242-4849-bbfb-9f79374316d2" /></span>,
//     created: "2024-04-04 10:10"
//   },
//   {
//     name: "API Key-03",
//     apikey: <span>128-xxx-652<SlCopyButton value="12836450-1ece-4ff2-ab93-d2836e202652" /></span>,
//     created: "2024-04-04 10:10"
//   },
//   {
//     name: "API Key-04",
//     apikey: <span>39c-xxx-7a4<SlCopyButton value="39cbb0ca-fae0-4bc1-a394-f4dd1f5a47a4" /></span>,
//     created: "2024-04-04 10:10"
//   },
//   {
//     name: "API Key-05",
//     apikey: <span>a83-xxx-0f7<SlCopyButton value="a834f4d4-9b01-44cd-ba89-ca83d7e3d0f7" /></span>,
//     created: "2024-04-04 10:10"
//   }
// ];

// const data = { columns, rows }

// const StyledTabContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 10px;
// `

// const StyledRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `
// const StyledLabel = styled.div`
//   font-size: var(--sl-font-size-small);
//   padding-bottom: 5px;
// `

export const ApiKeysSettings = () => {
  // const [open, setOpen] = useState(false);

  return (
    <>
      <StyledContainer>
        <StyledItems>
          <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>API Keys</strong> not supported or enabled for this environment.
        </StyledItems>
      </StyledContainer>
      {/* <StyledTabContainer>
        <StyledRow>
          <TableView data={data}>
            <SlButton variant="primary" size="medium" onClick={() => setOpen(true)}>
              <SlIcon slot="prefix" name="x" style={{ fontSize: '1.2rem' }}></SlIcon>
              Delete
            </SlButton>
            <SlButton variant="primary" size="medium" onClick={() => setOpen(true)}>
              <SlIcon slot="prefix" name="plus" style={{ fontSize: '1.2rem' }}></SlIcon>
              Create
            </SlButton>
          </TableView>
        </StyledRow>
      </StyledTabContainer>
      <SlDrawer label="Create an API key" open={open} onSlAfterHide={() => setOpen(false)}>
        <SlInput clearable name="name"><StyledLabel slot='label'>Name</StyledLabel></SlInput>
        <br />
        <SlButton slot="footer" variant="text" onClick={() => setOpen(false)}>
          Cancel
        </SlButton>
        <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
          Save
        </SlButton>
      </SlDrawer> */}
    </>
  )
}