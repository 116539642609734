import styled from 'styled-components';

import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 10px;
`

const StyledItems = styled.div`
  width: calc((95vw - var(--mx-sidebar-width) - 200px)/3);
  height: 160px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 4px;
  padding: 20px;
  // font-size: var(--sl-font-size-medium);
`

export const IntegrationsSettings = () => {
  return (
    <StyledContainer>
      <StyledItems>
        <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>Email</strong> - Integrations not supported or enabled for this environment.
      </StyledItems>
      <StyledItems>
        <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>JIRA</strong> - Integrations not supported or enabled for this environment.
      </StyledItems>
      <StyledItems>
        <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>Slack</strong> - Integrations not supported or enabled for this environment.
      </StyledItems>
      <StyledItems>
        <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>Microsoft Teams</strong> - Integrations not supported or enabled for this environment.
      </StyledItems>
      <StyledItems>
        <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>Webhooks</strong> - Integrations not supported or enabled for this environment.
      </StyledItems>
    </StyledContainer>
  )
}