import React from "react";

import Chart from "react-apexcharts";
import { useTheme } from 'styled-components'

export const DonutChart = (props) => {
  const theme = useTheme()

  const options = {
    chart: {
      type: 'donut',
      foreColor: theme.color.text
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%'
        }
      }
    },
    labels: props?.labels,
    dataLabels: {
      style: {
        fontWeight: 500,
        colors: [theme.color.text]
      },
      dropShadow: {
        enabled: false
      }
    },
    theme: {
      monochrome: {
        enabled: true
      }
    },
    legend: {
      // position: 'left',
      offsetY: -10,
      itemMargin: {
        vertical: 5
      },
      fontSize: 8
    },
    tooltip: {
      fillSeriesColor: false,
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      y: {
        title: {
          // eslint-disable-next-line no-unused-vars
          formatter: function (seriesName) {
            return ''
          }
        }
      },
      marker: {
        show: false
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return <Chart width="380" options={options} series={props.series} type="donut" />;
};
