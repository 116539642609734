import { create } from 'zustand'
// import secureLocalStorage from "react-secure-storage";
import { SKEY_APPSTORE } from "lib/core/localstore"

import {
  createAssetSlice,
  createAssetSlice2,
  createFindingSlice,
  createOrgSlice,
  createScanSlice,
  createStatsSlice,
} from './slices'

import { persist, createJSONStorage } from 'zustand/middleware'

// const SecureStorage = {
//   getItem: (name) => secureLocalStorage.getItem(name),
//   setItem: (name, value) => secureLocalStorage.setItem(name, value),
//   removeItem: (name) => secureLocalStorage.removeItem(name)
// };

export const useAppStore = create(
  persist(
    (...a) => ({
      ...createAssetSlice(...a),
      ...createAssetSlice2(...a),
      ...createFindingSlice(...a),
      ...createOrgSlice(...a),
      ...createScanSlice(...a),
      ...createStatsSlice(...a),
    }),
    {
      name: SKEY_APPSTORE,
      // storage: createJSONStorage(() => SecureStorage), // (optional) by default, 'localStorage' is used
      storage: createJSONStorage(() => sessionStorage),   // (optional) by default, 'localStorage' is used
    },
  ),
)

export const wrapPromise = (promise) => {
  let status = "pending";
  let result;

  const s = promise.then(
    (value) => {
      status = "success";
      result = value;
    },
    (error) => {
      status = "error";
      result = error;
    }
  );

  return () => {
    switch (status) {
    case "pending":
      throw s;
    case "success":
      return result;
    case "error":
      throw result;
    default:
      throw new Error("Unknown status");
    }
  };
};