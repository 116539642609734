import { CreateSlice2 } from "stores/createSlice2"

export const createFindingSlice = CreateSlice2("finding", [{
  "name": "overview",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/finding/overview",
  }
}, {
  "name": "workspaces",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/finding/workspace",
  }
}, {
  "name": "findings",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/findings",
  }
}, {
  "name": "compliance",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/finding/compliance",
  }
}])
