/* eslint-disable no-unused-vars */
import { css }  from "styled-components";
import React from "react";

import Chart from "react-apexcharts";

const style = css`
  .row {
      overflow: hidden;
      max-width: 890px;
      margin: 10px auto;
      display: flex;
  }

  .col-md-4 {
    width: 25.33%;
    padding: 0 25px;
  }
`

export const SLAreaChart = () => {

  const data = {
    series: [{
      data: [26, 20, 32, 35, 40, 23, 22, 28, 29, 27]
    }],

    options: {
      chart: {
        type: 'area',
        height: 160,
        sparkline: {
          enabled: true
        },
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 0.3,
      },
      yaxis: {
        min: 0
      },
      colors: ['#DCE6EC'],
      title: {
        text: '424',
        offsetX: 0,
        style: {
          fontSize: '24px',
        }
      },
      subtitle: {
        text: 'Open Issues',
        offsetX: 0,
        style: {
          fontSize: '14px',
        }
      }
    },

    seriesSpark2: [{
      data: [38, 40, 35, 24, 37, 33, 31, 30, 39, 25]
    }],
    optionsSpark2: {
      chart: {
        type: 'area',
        height: 160,
        sparkline: {
          enabled: true
        },
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 0.3,
      },
      yaxis: {
        min: 0
      },
      colors: ['#DCE6EC'],
      title: {
        text: '235',
        offsetX: 0,
        style: {
          fontSize: '24px',
        }
      },
      subtitle: {
        text: 'Open Issues',
        offsetX: 0,
        style: {
          fontSize: '14px',
        }
      }
    },

    seriesSpark3: [{
      data: [21, 39, 36, 29, 38, 23, 34, 22, 33, 37]
    }],
    optionsSpark3: {
      chart: {
        type: 'area',
        height: 160,
        sparkline: {
          enabled: true
        },
      },
      theme: {
        mode: 'dark',
        palette: 'palette1' // upto palette10
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      fill: {
        opacity: 0.3
      },
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      yaxis: {
        min: 0
      },
      title: {
        text: '135',
        offsetX: 0,
        style: {
          fontSize: '24px',
        }
      },
      subtitle: {
        text: 'Open Issues',
        offsetX: 0,
        style: {
          fontSize: '14px',
        }
      }
    },

    series1: [{
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }],
    options1: {
      chart: {
        type: 'line',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return ''
            }
          }
        },
        marker: {
          show: false
        }
      }
    },

    series2: [{
      data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
    }],
    options2: {
      chart: {
        type: 'line',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return ''
            }
          }
        },
        marker: {
          show: false
        }
      }
    },

    series3: [43, 32, 12, 9],
    options3: {
      chart: {
        type: 'pie',
        width: 40,
        height: 40,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        width: 1
      },
      tooltip: {
        fixed: {
          enabled: false
        },
      }
    },

    series4: [43, 32, 12, 9],
    options4: {
      chart: {
        type: 'donut',
        width: 40,
        height: 40,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        width: 1
      },
      tooltip: {
        fixed: {
          enabled: false
        },
      }
    },

    series5: [{
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }],
    options5: {
      chart: {
        type: 'bar',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '80%'
        }
      },
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return ''
            }
          }
        },
        marker: {
          show: false
        }
      }
    },

    series6: [{
      data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
    }],
    options6: {
      chart: {
        type: 'bar',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '80%'
        }
      },
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return ''
            }
          }
        },
        marker: {
          show: false
        }
      }
    },

    series7: [45],
    options7: {
      chart: {
        type: 'radialBar',
        width: 50,
        height: 50,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '50%'
          },
          track: {
            margin: 0
          },
          dataLabels: {
            show: false
          }
        }
      }
    },

    series8: [53, 67],
    options8: {
      chart: {
        type: 'radialBar',
        width: 40,
        height: 40,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '50%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: false
          }
        }
      }
    },
  };

  return (
    <>
      <div>
        <div>
          <div className="row">
            <div className="col-md-4">
              <div id="chart-spark1">
                <Chart options={data.options} series={data.series} type="area" height={160} />
              </div>
            </div>
            <div className="col-md-4">
              <div id="chart-spark2">
                <Chart options={data.optionsSpark2} series={data.seriesSpark2} type="area"
                  height={160} />
              </div>
            </div>
            <div className="col-md-4">
              <div id="chart-spark3">
                <Chart options={data.optionsSpark3} series={data.seriesSpark3} type="area"
                  height={160} />
              </div>
            </div>
          </div>

          <div className="row">
            <table>
              <thead>
                <th>Total Value</th>
                <th>Percentage of Portfolio</th>
                <th>Last 10 days</th>
                <th>Volume</th>
              </thead>
              <tbody>
                <tr>
                  <td>554</td>
                  <td>15%</td>
                  <td>
                    <div id="chart-1">
                      <Chart options={data.options1} series={data.series1} type="line" height={35}
                        width={100} />
                    </div>
                  </td>
                  <td>
                    <div id="chart-5">
                      <Chart options={data.options5} series={data.series5} type="bar" height={35}
                        width={100} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>23</td>
                  <td>7%</td>
                  <td>
                    <div id="chart-2">
                      <Chart options={data.options2} series={data.series2} type="line" height={35}
                        width={100} />
                    </div>
                  </td>
                  <td>
                    <div id="chart-6">
                      <Chart options={data.options6} series={data.series6} type="bar" height={35}
                        width={100} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>276</td>
                  <td>9%</td>
                  <td>
                    <div id="chart-3">
                      <Chart options={data.options3} series={data.series3} type="pie" height={40}
                        width={40} />
                    </div>
                  </td>
                  <td>
                    <div id="chart-7">
                      <Chart options={data.options7} series={data.series7} type="radialBar" height={50}
                        width={50} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>2%</td>
                  <td>
                    <div id="chart-4">
                      <Chart options={data.options4} series={data.series4} type="donut" height={40}
                        width={40} />
                    </div>
                  </td>
                  <td>
                    <div id="chart-8">
                      <Chart options={data.options8} series={data.series8} type="radialBar" height={40}
                        width={40} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="html-dist"></div>
      </div>
      <style>{style}</style>
    </>
  );
}