import { CreateSlice2 } from "stores/createSlice2"

export const createStatsSlice = CreateSlice2("stat", [
  {
    "name": "severity",
    "http_req": {
      "method": "get",
      "url": "https://api.cloudmatos.ai/console/v1/stat/severity",
    }
  }, {
    "name": "trend",
    "http_req": {
      "method": "get",
      "url": "https://api.cloudmatos.ai/console/v1/stat/trend",
    }
  }, {
    "name": "category",
    "http_req": {
      "method": "get",
      "url": "https://api.cloudmatos.ai/console/v1/stat/category",
    }
  }, {
    "name": "workspace",
    "http_req": {
      "method": "get",
      "url": "https://api.cloudmatos.ai/console/v1/stat/workspace",
    }
  }
])