import styled from 'styled-components';
import React, { useState, useContext, useRef } from 'react';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { ReactComponent as IconLogo } from "assets/icons/logo.svg";
import { AppContext } from 'context';
import { GetUser } from "lib/core/localstore";

const StyledIconContainer = styled.span`
  display: inline-block;
  padding: 0px 4px;
`

const StyledLogoContiner = styled(StyledIconContainer)`
  margin-left: -5px;
  margin-right: 15px;
`

const StyledTitle = styled.span`
  font-weight: var(--sl-font-weight-semibold);
  font-size: var(--sl-font-size-small);
  font-weight: var(--sl-font-weight-bold);
  position: relative;
  top: -0.5rem;
  transition: visibility 1s, opacity 1s;
`

export function NavigationMenuItems() {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const userinfo = useRef(GetUser())
  const context = useContext(AppContext);

  function toggleTheme() {
    var profile = {
      ...context.profile,
      lightTheme: !context.profile.lightTheme
    }
    context.setProfile(profile);
  }

  function toggleSidebar() {
    var profile = {
      ...context.profile,
      sidebarOpen: !context.profile.sidebarOpen
    }
    context.setProfile(profile);
  }

  return (
    <>
      <div>
        <StyledLogoContiner>
          <IconLogo width="24px" onClick={() => toggleSidebar()} />
        </StyledLogoContiner>
        <StyledTitle style={context.profile.sidebarOpen ? { visibility: "visible", opacity: 1 } : { visibility: "hidden", opacity: 0 }}>Metos ASM</StyledTitle>
      </div>
      <div>
        <StyledIconContainer>
          <SlTooltip content={context.profile.lightTheme ? "Switch to dark mode" : "Switch to light mode"}>
            <SlButton variant="default" size="large" circle className="navlink" onClick={() => toggleTheme()}>
              <SlIcon name={context.profile.lightTheme ? "sun" : "moon-stars"} />
            </SlButton>
          </SlTooltip>
        </StyledIconContainer>
        <StyledIconContainer>
          <SlButton variant="default" size="large" circle className="navlink" onClick={() => setOpen(true)}>
            <SlIcon name="bell" />
          </SlButton>
        </StyledIconContainer>
        {
          (userinfo && userinfo?.current) && (
            <StyledIconContainer>
              <SlTooltip style={{ '--max-width': '200px' }} placement="bottom-end">
                <div slot="content">
                  <strong>Sign out</strong><br />
                  <em>{userinfo?.current?.i}</em>
                </div>
                <SlButton variant="default" size="large" circle className="navlink" href="/signout">
                  <SlIcon name="box-arrow-right" />
                </SlButton>
              </SlTooltip>
            </StyledIconContainer>
          )
        }
      </div>
      <SlDrawer label="Notifications" open={open} onSlAfterHide={() => setOpen(false)}>
        No Messages
        <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
          Close
        </SlButton>
      </SlDrawer>
    </>
  )
}