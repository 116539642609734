import { Page, PageHeader, PageBody, Title, Toolbar } from "lib/core/flex"

import { StyledTabs } from "lib/components/tabs";
import {
  GeneralSettings, UsersSettings, WorkspacesSettings, ApiKeysSettings,
  SchedulerSettings, IntegrationsSettings, PaymentsSettings
} from "routes/partials/settings";

const items = [
  {
    id: "general",
    name: "General",
    children: <GeneralSettings />
  },
  {
    id: "users",
    name: "Users",
    children: <UsersSettings />
  },
  {
    id: "workspaces",
    name: "Workspaces",
    children: <WorkspacesSettings />
  },
  {
    id: "apikeys",
    name: "API Keys",
    children: <ApiKeysSettings />
  },
  {
    id: "scheduler",
    name: "Scheduler",
    children: <SchedulerSettings />
  },
  {
    id: "integrations",
    name: "Integrations",
    children: <IntegrationsSettings />
  },
  {
    id: "payments",
    name: "Payments",
    children: <PaymentsSettings />
  }
];

export default function Settings() {
  return (
    <Page route="settings">
      <PageHeader>
        <Title>Settings</Title>
        <Toolbar></Toolbar>
      </PageHeader>
      <PageBody $m5>
        <StyledTabs items={items} />
      </PageBody>
    </Page>
  )
}