import useSessionData from "stores/useSessionData";
import { useEffect, useState } from 'react';
import { FlexCols } from "lib/core/flex";

import Treemap from "lib/charts/treemap";
import Polararea from "lib/charts/polararea";

import NoData from "lib/sx/no_data";

export const TopWorkspaceByCount = () => {
  var args = {
    slice_name: "stat",
    store_name: "workspace"
  }

  var resource = useSessionData(args)

  return (
    resource && (
      resource?.data?.count?.length > 0 ? (
        <FlexCols $g10 style={{ fontSize: "0.75rem" }}>
          <Treemap series={resource?.data?.count} />
        </FlexCols>
      ) : <NoData />
    )
  )
}

export const TopWorkspaceBySeverity = (props) => {
  var args = {
    slice_name: "stat",
    store_name: "workspace"
  }

  var resource = useSessionData(args)
  const [nomalized, setNomalized] = useState(null)

  useEffect(() => {
    var data = { series: [], labels: [] }
    var list = resource?.data?.severity?.critical

    switch (props?.kind) {
    case "high":
      list = resource?.data?.severity?.high
      break;
    case "medium":
      list = resource?.data?.severity?.medium
      break;
    case "low":
      list = resource?.data?.severity?.low
      break;
    default:
      list = resource?.data?.severity?.critical
      break;
    }

    if (list?.length > 0) {
      list?.forEach((item) => {
        data.labels.push(item.x)
        data.series.push(item.y)
      })
    }

    setNomalized(data)
  }, [resource, props?.kind]);

  return (
    nomalized && (
      nomalized.series.length > 0 ? (
        <FlexCols style={{ fontSize: "0.75rem" }}>
          <Polararea series={nomalized.series} labels={nomalized.labels} />
        </FlexCols>
      ) : <NoData />
    )
  )
}
