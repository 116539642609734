import { styled } from "styled-components";

import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';
import { useEffect, useState } from "react";

const StyledTableContainer = styled.div`
`

const StyledTableToolbar = styled.div`
  display:flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-end;
`

const StyledTableFooter = styled.div`
  // border: 1px solid red;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledTable = styled.table`
  line-height: 1.7142857143rem;
  width: 100%;
  table-layout: fixed;
  border: none;
  border-collapse: collapse;
  border-spacing: 0px;
  
`
const StyledTHead = styled.thead`
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  vertical-align: middle;
  border-bottom: 1px solid #d3dae6;
  text-align: left;
  overflow-wrap: break-word !important;
  word-break: break-word;
  line-height: 1.7142857143rem;
  font-weight: 500;
`
const StyledTBody = styled.tbody``
const StyledTRow = styled.tr`
  display: table-row;
  vertical-align: inherit;
  height: 45px;
  padding: 50px;
`
const StyledTHeader = styled.th`
  padding: 5px;
  &.checkbox {
    &:nth-child(1) {
      width: 30px;
    }
  }
`
const StyledTData = styled.td`
  padding: 5px;
  vertical-align: middle;
  // border-top: 1px solid ${({ theme }) => theme.color.border};
  // border-bottom: 1px solid ${({ theme }) => theme.color.border};
  border-bottom: 1px dotted ${({ theme }) => theme.color.border};
  text-align: left;
`

const StyledPagination = styled.div`
  // margin-right: var(--sl-spacing-x-small);
`

export const TableView = ({ children, data, options }) => {
  if (!data && !data?.rows) {
    return (<StyledTableContainer></StyledTableContainer>)
  }

  const opt = { checkbox: true, toolbar: true, tooltip: true, ipp: true, defIpp: 10, ...options}
  const defPage = 1, defStart = 0, defIpp = opt?.defIpp
  const [page, setPage] = useState(defPage)
  const [ipp, setipp] = useState(defIpp)
  const [rowCount, setRowCount] = useState(data?.rows?.length)
  const [maxPage, setMaxpages] = useState(Math.ceil(rowCount / ipp))
  const [start, setStart] = useState(defStart)
  const [end, setEnd] = useState(rowCount > defIpp ? defIpp : rowCount)
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState(data?.rows?.filter(filterRows))

  function handleSelect(event) {
    const item = event.detail.item;
    const selectedVal = Number(item.value)
    setPage(defPage)
    setipp(selectedVal)
    setStart(defStart)
    setEnd(rowCount > selectedVal ? selectedVal : rowCount)
    setMaxpages(Math.ceil(rowCount / selectedVal))
  }

  function handlePrev() {
    if (page > 1) {
      setPage(page - 1)
      setStart(start - ipp)
      setEnd(end - ipp)
    }
  }

  function handleNext() {
    if (page < maxPage) {
      setPage(page + 1)
      setStart(start + ipp)
      setEnd(end + ipp)
    }
  }

  useEffect(() => {
    var filteredRows = data?.rows?.filter(filterRows)
    if (filteredRows) {
      setRows(filteredRows)
      setRowCount(filteredRows?.length)
    }
  }, [search])

  useEffect(() => {
    setPage(defPage)
    setStart(defStart)
    setEnd(rowCount > ipp ? ipp : rowCount)
    setMaxpages(Math.ceil(rowCount / ipp))
  }, [rowCount])

  function filterRows(row) {
    if (search.length < 3) {
      return true
    }

    // eslint-disable-next-line no-unused-vars
    var result = data?.columns?.map((column) => {
      if (String(row[column?.field]).includes(search)) {
        return true
      }
      return false
    })

    return (result.indexOf(true) != -1)
  }

  function showField(value) {
    if (value === "") {
      return "-"
    }
    return value
  }

  return (
    <StyledTableContainer>
      {opt.toolbar && (
        <StyledTableToolbar>
          <SlInput placeholder="Search" clearable style={{ width: "100%" }} onSlInput={e => { setSearch(e.target.value) }} />
          {children}
        </StyledTableToolbar>
      )}
      <StyledTable>
        <StyledTHead>
          <StyledTRow>
            {opt.checkbox && (<StyledTHeader className="checkbox"><SlCheckbox /></StyledTHeader>)}
            {data?.columns?.map((column, i) => (
              <StyledTHeader key={i}>
                {/* <SlButton variant="text" onClick={()=>alert("hello")}>
                  {column?.display}
                </SlButton> */}
                {column?.display}
              </StyledTHeader>
            ))}
          </StyledTRow>
        </StyledTHead>
        <StyledTBody>
          {rows?.slice(start, end)?.map((row, i) => (
            <StyledTRow key={i}>
              {opt.checkbox && (<StyledTData className="checkbox"><SlCheckbox /></StyledTData>)}
              {data?.columns?.map((column, j) => (
                <StyledTData key={j}>{showField(row[column?.field])}</StyledTData>
              ))}
            </StyledTRow>
          ))}
        </StyledTBody>
      </StyledTable>
      <br />
      <StyledTableFooter>
        <div>
          {
            opt.ipp && (
              <>
                <SlDropdown>
                  <SlButton slot="trigger" caret>
                    {ipp}
                  </SlButton>
                  <SlMenu onSlSelect={handleSelect}>
                    <SlMenuItem value="5">5</SlMenuItem>
                    <SlMenuItem value="10">10</SlMenuItem>
                    <SlMenuItem value="15">15</SlMenuItem>
                    <SlMenuItem value="20">20</SlMenuItem>
                  </SlMenu>
                </SlDropdown><span>&nbsp;&nbsp;&nbsp;Items per page</span>
              </>
            )
          }

        </div>
        <StyledPagination>
          {
            maxPage > 0 && (
              <>
                <span>{page} of {maxPage} &nbsp;&nbsp;&nbsp;</span>
                <SlButtonGroup label="Pagination">
                  {
                    opt.tooltip && (
                      <>
                        <SlTooltip content="Prev">
                          <SlButton size="medium" onClick={handlePrev}>
                            <SlIcon name="arrow-left"></SlIcon>
                          </SlButton>
                        </SlTooltip>
                        <SlTooltip content="Next">
                          <SlButton size="medium" onClick={handleNext}>
                            <SlIcon name="arrow-right"></SlIcon>
                          </SlButton>
                        </SlTooltip>
                      </>
                    )
                  }
                  {
                    !opt.tooltip && (
                      <>
                        <SlButton size="medium" onClick={handlePrev}>
                          <SlIcon name="arrow-left"></SlIcon>
                        </SlButton>
                        <SlButton size="medium" onClick={handleNext}>
                          <SlIcon name="arrow-right"></SlIcon>
                        </SlButton>
                      </>
                    )
                  }
                </SlButtonGroup>
              </>
            )
          }
        </StyledPagination>
      </StyledTableFooter>
    </StyledTableContainer>
  )
}