import { Page, PageHeader, PageBody, Title, Toolbar, ToolbarItem, FlexCols, FlexItem, FlexRows } from "lib/core/flex";
import styled from "styled-components";

import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';

import { DataSafeComponent } from 'routes/main'
import {
  RiskScore,
  Severity,
  Trend,
  Category,
  TopWorkspaceByCount,
  TopWorkspaceBySeverity,
  SummaryAssets,
  SummaryAttackSurface,
  SummaryScans,
  Overview,
  Compliances,
} from "routes/partials/dashboard";
import { useState, useEffect } from "react";

const Panel = styled(FlexItem).attrs({
})`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
 
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const PanelOuter = styled(FlexItem).attrs({
})`
  display: flex;
  // flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
 
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const PanelTitle = styled(FlexItem).attrs({
})`
  font-weight: 600;
`
function ToolbarItems(props) {
  const [selected, setSelected] = useState(1)

  useEffect(() => {
    props.onViewChange(selected)
  }, [selected])

  function isSelected(id) {
    return (selected == id) ? "primary" : "default"
  }

  return (
    <ToolbarItem>
      <SlButtonGroup label="Views">
        <SlTooltip content="Overview">
          <SlButton variant={isSelected(1)} size="medium" onClick={() => setSelected(1)}>
            <SlIcon name="layout-wtf"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="Category">
          <SlButton variant={isSelected(2)} size="medium" onClick={() => setSelected(2)}>
            <SlIcon name="card-heading"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="Compliance">
          <SlButton variant={isSelected(3)} size="medium" onClick={() => setSelected(3)}>
            <SlIcon name="patch-check"></SlIcon>
          </SlButton>
        </SlTooltip>
      </SlButtonGroup>
    </ToolbarItem>
  )
}



function HomeView() {
  const [severity, SetSeverity] = useState("Critical")

  function handleSelect(event) {
    event.preventDefault();

    const item = event.detail.item;
    SetSeverity(item.value)
  }

  return (
    <FlexCols $g20>
      <FlexRows $g20 style={{ height: "215px" }}>
        <PanelOuter $fx="3">
          <Panel $fx="1">
            <PanelTitle>Risk score</PanelTitle>
            <SlDivider />
            <FlexItem $asc>
              <FlexCols $g10 style={{ fontSize: "0.75rem" }}>
                <DataSafeComponent>
                  <RiskScore />
                </DataSafeComponent>
              </FlexCols>
            </FlexItem>
          </Panel>
          <Panel $fx="2">
            <PanelTitle>Findings by severity</PanelTitle>
            <SlDivider />
            <FlexItem style={{ width: "100%" }} $asc>
              <DataSafeComponent>
                <Severity />
              </DataSafeComponent>
            </FlexItem>
          </Panel>
        </PanelOuter>
        <PanelOuter $fx="2">
          <Panel $fx="2">
            <PanelTitle>Findings trend</PanelTitle>
            <SlDivider />
            <FlexItem $asc>
              <FlexCols $g5>
                <FlexRows style={{ margin: "-20px 0px" }}>
                  <DataSafeComponent>
                    <Trend />
                  </DataSafeComponent>
                </FlexRows>
              </FlexCols>
            </FlexItem>
          </Panel>
        </PanelOuter>
      </FlexRows>
      <FlexRows $g20 style={{ height: "325px" }}>
        <PanelOuter $fx="3">
          <Panel $fx="3">
            <PanelTitle>Findings by category</PanelTitle>
            <SlDivider />
            <FlexItem $asc>
              <FlexCols>
                <DataSafeComponent>
                  <Category />
                </DataSafeComponent>
              </FlexCols>
            </FlexItem>
          </Panel>
        </PanelOuter>
        <PanelOuter $fx="2">
          <Panel $fx="3">
            <PanelTitle>Top workspace - findings by count</PanelTitle>
            <SlDivider />
            <FlexItem $asc>
              <DataSafeComponent>
                <TopWorkspaceByCount />
              </DataSafeComponent>
            </FlexItem>
          </Panel>
        </PanelOuter>
      </FlexRows>
      <FlexRows $g20 style={{ height: "240px" }}>
        <PanelOuter $g20 $fx="3">
          <Panel $fx="1">
            <PanelTitle $asc>Attack surface</PanelTitle>
            <SlDivider />
            <DataSafeComponent>
              <SummaryAttackSurface />
            </DataSafeComponent>
          </Panel>
          <Panel $fx="1">
            <PanelTitle $asc>Assets</PanelTitle>
            <SlDivider />
            <DataSafeComponent>
              <SummaryAssets />
            </DataSafeComponent>
          </Panel>
          <Panel $fx="1">
            <PanelTitle $asc>Scans</PanelTitle>
            <SlDivider />
            <DataSafeComponent>
              <SummaryScans />
            </DataSafeComponent>
          </Panel>
        </PanelOuter>
        <PanelOuter $fx="2">
          <Panel $fx="2">
            <PanelTitle>
              Top workspace - findings by severity &nbsp;&nbsp;
              <SlDropdown>
                <SlButton slot="trigger" caret>
                  {severity}
                </SlButton>
                <SlMenu onSlSelect={e => handleSelect(e)}>
                  <SlMenuItem value="Critical">Critical</SlMenuItem>
                  <SlMenuItem value="High">High</SlMenuItem>
                  <SlMenuItem value="Medium">Medium</SlMenuItem>
                  <SlMenuItem value="Low">Low</SlMenuItem>
                </SlMenu>
              </SlDropdown>
            </PanelTitle>
            <SlDivider />
            <FlexItem $asc>
              <DataSafeComponent>
                <TopWorkspaceBySeverity kind={severity.toLowerCase()} />
              </DataSafeComponent>
            </FlexItem>
          </Panel>
        </PanelOuter>
      </FlexRows>
    </FlexCols >
  )
}

function OverviewView() {
  return (
    <>
      <DataSafeComponent>
        <Overview />
      </DataSafeComponent>
    </>
  )
}

function ComplianceView() {
  return (
    <>
      <DataSafeComponent>
        <Compliances />
      </DataSafeComponent>
    </>
  )
}

export default function Dashboard() {
  const [content, setContent] = useState(<HomeView />)
  const [title, setTitle] = useState(<span>Dashboard &nbsp;/&nbsp; Overview</span>)

  function handleViewChange(view) {
    switch (view) {
    case 2:
      setContent(<OverviewView />)
      setTitle(<span>Dashboard &nbsp;/&nbsp; Category</span>)
      break;
    case 3:
      setContent(<ComplianceView />)
      setTitle(<span>Dashboard &nbsp;/&nbsp; Compliance</span>)
      break;
    default:
      setContent(<HomeView />)
      setTitle(<span>Dashboard &nbsp;/&nbsp; Overview</span>)
      break;
    }
  }

  return (
    <Page route="dashboard">
      <PageHeader>
        <Title>{title}</Title>
        <Toolbar><ToolbarItems onViewChange={handleViewChange} /></Toolbar>
      </PageHeader>
      <PageBody $m15>
        {content}
      </PageBody>
    </Page>
  )
}