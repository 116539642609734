import { useState } from 'react';
import { Page, PageHeader, PageBody, Title, Toolbar } from 'lib/core/flex';
import { DataSafePage, } from 'routes/main';

import {
  ToolbarItems,
  Content,
} from 'routes/partials/attackSurface';


export default function AttackSurface() {
  const [view, setView] = useState(1)

  return (
    <Page route="attack-surface">
      <PageHeader>
        <Title>Attack Surface</Title>
        <Toolbar><ToolbarItems setView={setView} /></Toolbar>
      </PageHeader>
      <PageBody $m15>
        <DataSafePage>
          <Content view={view} />
        </DataSafePage>
      </PageBody>
    </Page>
  )
}