import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
@charset "UTF-8";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.text};
  font-family: var(--sl-font-sans);
  font-size: var(--sl-font-size-small);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`