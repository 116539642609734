import secureLocalStorage from "react-secure-storage";
import { heartBeat } from "./bera";

const SKEY_TOKEN = "9224F477-971C-4ED9-A236-5CFC435C243A"
const SKEY_UNAME = "9A6BE722-02AF-4FC1-8FE5-646AA3BA9F49"
const SKEY_USER = "299382CD-3647-4FBC-ABE3-5F696F75EECF"

export const SKEY_APPSTORE = "63F53978-107F-4D67-9FE4-85E41261CD7D"

export function SetToken(value) {
  localStorage.setItem(SKEY_TOKEN, value)
  RefreshSession()
}

export function GetToken() {
  return localStorage.getItem(SKEY_TOKEN)
}

export function RemoveToken() {
  localStorage.removeItem(SKEY_TOKEN)
  RefreshSession()
}

export function RefreshSession() {
  sessionStorage.removeItem(SKEY_APPSTORE)
}

export async function ValidateToken(checkToken = true) {

  var condition = checkToken ? GetToken() !== null : true;

  if (condition) {
    return heartBeat()
  }
}

export function SetUname(value) {
  secureLocalStorage.setItem(SKEY_UNAME, value)
}

export function GetUname() {
  return secureLocalStorage.getItem(SKEY_UNAME)
}

export function RemoveUname() {
  secureLocalStorage.removeItem(SKEY_UNAME)
}

export function SetUser(value) {
  secureLocalStorage.setItem(SKEY_USER, JSON.stringify(value))
}

export function GetUser() {
  return JSON.parse(secureLocalStorage.getItem(SKEY_USER))
}

export function RemoveUser() {
  secureLocalStorage.removeItem(SKEY_USER)
}