import { CreateSlice2 } from "stores/createSlice2"

export const createScanSlice = CreateSlice2("scan", [{
  "name": "summary",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/stat/scan"
  }
}, {
  "name": "items",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/scan/snapshot"
  }
}])