import { Page, PageHeader, PageBody, Title, Toolbar } from "lib/core/flex"
import { StyledTabs } from "lib/components/tabs";
import { AccessLogs, AppLogs, Notifications } from "routes/partials/activity";
import { DataSafeComponent } from 'routes/main'

const items = [
  {
    id: "applogs",
    name: "Application Logs",
    children: (
      <DataSafeComponent>
        <AppLogs />
      </DataSafeComponent>
    )
  },
  {
    id: "accesslogs",
    name: "Access Logs",
    children: (
      <DataSafeComponent>
        <AccessLogs />
      </DataSafeComponent>
    )
  },
  {
    id: "notifications",
    name: "Notifications",
    children: (
      <DataSafeComponent>
        <Notifications />
      </DataSafeComponent>
    )
  }
];

export default function Activity() {
  return (
    <Page route="activity">
      <PageHeader>
        <Title>Activity</Title>
        <Toolbar></Toolbar>
      </PageHeader>
      <PageBody $m5>
        <StyledTabs items={items} />
      </PageBody>
    </Page>
  )
}