import React from "react";

import Chart from "react-apexcharts";
import { useTheme } from 'styled-components'

export const TrendChart = (props) => {
  const theme = useTheme()

  const options = {
    chart: {
      type: 'bar',
      foreColor: theme.color.text,
      toolbar: {
        show: false
      },
    },
    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      y: {
        show: false
      },
      marker: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: [theme.color.text]
      }
    },
    xaxis: {
      categories: props?.categories,
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        }
      }

    },
    // title: {
    //   text: 'Monthly Inflation in Argentina, 2002',
    //   floating: true,
    //   offsetY: 330,
    //   align: 'center',
    //   style: {
    //     color: '#444'
    //   }
    // }
  };

  return <Chart type="bar" options={options} series={props?.series} height={200} />;
};
