import { Suspense } from 'react';
import ErrorBoundary from "lib/core/error_boundary";
import NetworkError from "lib/sx/network_error";
import Skeleton from './skeleton';

export default function DataSafePage({ children }) {
  return (
    <ErrorBoundary fallback={<NetworkError />}>
      <Suspense fallback={<Skeleton />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}