import styled from 'styled-components';
import { useState, useRef } from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { TableView } from "lib/components/table";
import { DataSafeComponent } from 'routes/main'

import { createUser, getUserList } from 'lib/core/bera';

const columns = [
  {
    field: "first_name",
    display: "Firstname"
  },
  {
    field: "last_name",
    display: "Lastname"
  },
  {
    field: "email",
    display: "Email"
  },
  {
    field: "role",
    display: "Role"
  },
  {
    field: "created",
    display: "Created"
  }
];

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLabel = styled.span`
  font-size: var(--sl-font-size-small);
  display: inline-block;
  padding-bottom: 5px;
`

function UserList(props) {
  const resource = getUserList()

  return resource && (
    <TableView data={{ columns, rows: resource?.data }}>
      <SlTooltip content="Edit user">
        <SlButton variant="default" size="medium">
          <SlIcon slot="prefix" name="person-gear" style={{ fontSize: '1.2rem' }}></SlIcon>
        </SlButton>
      </SlTooltip>
      <SlTooltip content="Remove user">
        <SlButton variant="default" size="medium">
          <SlIcon slot="prefix" name="person-dash" style={{ fontSize: '1.2rem' }}></SlIcon>
        </SlButton>
      </SlTooltip>
      <SlTooltip content="Add user">
        <SlButton variant="default" size="medium" onClick={() => props.setOpen(true)}>
          <SlIcon slot="prefix" name="person-add" style={{ fontSize: '1.2rem' }}></SlIcon>
        </SlButton>
      </SlTooltip>
    </TableView>
  )
}

export const UsersSettings = () => {
  const [open, setOpen] = useState(false);
  const first_name = useRef(null);
  const last_name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async event => {
    setLoading(true)
    event.preventDefault();

    // eslint-disable-next-line no-unused-vars
    const response = await createUser({
      "first_name": first_name.current.value,
      "last_name": last_name.current.value,
      "email": email.current.value,
      "password": password.current.value
    });

    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <StyledTabContainer>
        <StyledRow>
          <DataSafeComponent>
            <UserList setOpen={setOpen} />
          </DataSafeComponent>
        </StyledRow>
      </StyledTabContainer>
      <form onSubmit={handleSubmit}>
        <SlDrawer label="Add user" open={open} onSlAfterHide={() => setOpen(false)} style={{ '--size': '40vw' }}>
          <SlInput clearable required name="first_name" ref={first_name} >
            <StyledLabel slot='label'>Firstname</StyledLabel>
          </SlInput>
          <br />
          <SlInput clearable required ref={last_name} >
            <StyledLabel slot='label'>Lastname</StyledLabel>
          </SlInput>
          <br />
          <SlInput clearable required type="email" ref={email} >
            <StyledLabel slot='label'>Username / Email</StyledLabel>
          </SlInput>
          <br />
          <SlInput password-toggle required type="password" ref={password} >
            <StyledLabel slot='label'>Password</StyledLabel>
          </SlInput>
          <br />
          <SlButton slot="footer" variant="text" onClick={() => setOpen(false)}>Cancel</SlButton>
          <SlButton slot="footer" variant="primary" loading={loading} type='submit'>Add</SlButton>
        </SlDrawer>
      </form>
    </>
  )
}