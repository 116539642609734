import axios from "axios";
import GetSecurityHeader from 'lib/core/security_header';

const defaultState = {
  d: null,  // data
  e: null,  // error
}

export const CreateSlice = (name, items) => {
  return (set, get) => {
    var innerStateObject = {}
    var innerFuncObject = {}

    items.forEach(item => {
      const objItem = { [item["name"]]: { ...defaultState } }
      innerStateObject = { ...innerStateObject, ...objItem }

      const funcItem = {
        [item["action"]]: async (args = {}) => {
          const { path, params, data, force } = args;
          var endpoint = item["url"];

          try {
            if (!force && get()[name][item["name"]]["d"] !== null) {
              const promise = new Promise((resolve) => {
                resolve(get()[name][item["name"]]["d"]);
              });
              return promise;
            }
            set((state) => ({
              [name]: { ...state[name], [item["name"]]: { ...defaultState } }
            }))
            if (path) {
              for (const key in path) {
                endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
              }
            }
            return axios({
              method: item["method"],
              url: endpoint,
              headers: GetSecurityHeader(),
              data,
              params,
            }).then((res) => {
              if (item["method"]?.toLowerCase() === "get") {
                set((state) => ({
                  [name]: { ...state[name], [item["name"]]: { ...defaultState, d: res.data } }
                }))
              }
              item["options"]?.onSuccess?.(res);
              args?.onSuccess?.(res);
              return res.data
            })
              .catch((err) => {
                set((state) => ({
                  [name]: { ...state[name], [item["name"]]: { ...defaultState, e: err.message } }
                }))
                item["options"]?.onError?.(err);
                args?.onError?.(err);
                throw err
              })
              .finally(() => {
                item["options"]?.onFinal?.();
                args?.onFinal?.();
              });
          } catch (err) {
            set((state) => ({
              [name]: { ...state[name], [item["name"]]: { ...defaultState, e: err.message } }
            }))
            item["options"]?.onError?.(err);
            args?.onError?.(err);
          }
        }
      }
      innerFuncObject = { ...innerFuncObject, ...funcItem }
    });

    const rootObject = {
      [name]: { ...innerStateObject },
      ...innerFuncObject
    }
    return rootObject
  }
}