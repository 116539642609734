import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

export default function NoData(props) {
  if (props.criteria !== undefined) {
    if (props.criteria) {
      return <span style={props?.style}><SlIcon name="info-circle"></SlIcon>&nbsp;&nbsp;No data available!</span>
    } else {
      return <></>
    }
  }

  return <span style={props?.style}><SlIcon name="info-circle"></SlIcon>&nbsp;&nbsp;No data available!</span>
}
