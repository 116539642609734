import { Page, PageHeader, PageBody, Title, Toolbar, FlexCols, FlexRows, FlexItem } from "lib/core/flex"
import React, { useState } from 'react';
import styled from "styled-components";

import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlRadioButton from '@shoelace-style/shoelace/dist/react/radio-button';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import { NoData } from 'routes/main'

const periodData = [
  // "10 Apr 2024",
  // "10 Mar 2024",
  // "10 Feb 2024",
  // "10 Jan 2024",
  // "10 Dec 2023",
  // "10 Nov 2023",
  // "10 Oct 2023",
  // "10 Sep 2023",
  // "10 Aug 2023",
  // "10 Jul 2023",
  // "10 Jun 2023",
  // "10 May 2023",
  // "10 Apr 2023",
  // "10 Mar 2023",
  // "10 Feb 2023",
  // "10 Jan 2023",
];

const scopeData = [
  // "ws-blogs",
  // "ws-customer support",
  // "ws-admin dev",
  // "ws-admin prod",
  // "ws-admin stage",
  // "ws-productions",
  // "ws-app group-a",
  // "ws-app group-b",
  // "ws-service",
  // "ws-promo",
];

const reportData = [
  // "10 Apr 2024",
  // "10 Mar 2024",
  // "10 Feb 2024",
  // "10 Jan 2024",
  // "10 Dec 2023",
  // "10 Nov 2023",
];

const Panel = styled.div`
  height: ${props => props.$ht || "74vh"};
  overflow-y: auto;
  margin: 0px -10px;
  padding: 0px 30px;
`

const ListItem = styled(FlexRows)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  }
`

function SelectPeriod(props) {

  function clickHandler() {

  }

  return (
    <SlDrawer label="Select Period" open={props.open} onSlAfterHide={() => props.setOpen(false)}>
      <FlexCols $g15>
        <Panel>
          <FlexCols $g10>
            {periodData.map((item, i) => (
              <ListItem $bd $pd15 $g20 key={i} onClick={() => clickHandler()}>
                <FlexItem $fx="1.5">
                  <FlexCols>
                    <FlexItem><span style={{ fontSize: "0.9rem" }}>{item}</span></FlexItem>
                  </FlexCols>
                </FlexItem>
              </ListItem>
            ))}
          </FlexCols>
        </Panel>
      </FlexCols>

      <SlButton slot="footer" variant="primary" onClick={() => props.setOpen(false)}>
        Close
      </SlButton>
    </SlDrawer>
  )
}

function SelectScope(props) {

  function clickHandler() {

  }

  return (
    <SlDrawer label="Select Scope" open={props.open} onSlAfterHide={() => props.setOpen(false)}>
      <FlexCols $g15>
        <Panel>
          <FlexCols $g10>
            {scopeData.map((item, i) => (
              <ListItem $bd $pd15 $g20 key={i} onClick={() => clickHandler()}>
                <FlexItem $fx="1.5">
                  <FlexCols>
                    <FlexItem><span style={{ fontSize: "0.9rem" }}>{item}</span></FlexItem>
                  </FlexCols>
                </FlexItem>
              </ListItem>
            ))}
          </FlexCols>
        </Panel>
      </FlexCols>
      <SlButton slot="footer" variant="primary" onClick={() => props.setOpen(false)}>
        Close
      </SlButton>
    </SlDrawer>
  )
}

export default function Reports() {
  const [period, setPeriod] = useState(false);
  const [scope, setScope] = useState(false);

  function periodClickHandler() {
    setPeriod(true)
  }

  function scopeClickHandler() {
    setScope(true)
  }

  return (
    <Page route="reports">
      <PageHeader>
        <Title>Reports</Title>
        <Toolbar></Toolbar>
      </PageHeader>
      <PageBody $m5>
        <SlTabGroup>
          <SlTab slot="nav" panel="new">
            New
          </SlTab>
          <SlTab slot="nav" panel="saved">
            Saved
          </SlTab>

          <SlTabPanel name="new">
            <FlexCols $g15>
              <FlexRows>
                <FlexItem $fx="1" $asc><strong>Period</strong></FlexItem>
                <FlexItem $fx="1">
                  <SlButton variant="default" onClick={() => periodClickHandler()}>
                    <SlIcon slot="prefix" name="calendar-date"></SlIcon>
                    10 Apr 2024
                  </SlButton>
                </FlexItem>
                <FlexItem $fx="1" $asc>
                  <strong>Scope</strong></FlexItem>
                <FlexItem $fx="1">
                  <SlButton variant="default" onClick={() => scopeClickHandler()}>
                    <SlIcon slot="prefix" name="card-checklist"></SlIcon>
                    All workspace
                  </SlButton>
                </FlexItem>
              </FlexRows>
              <SlDivider style={{ '--spacing': '2rem' }} />
              <FlexRows>
                <FlexItem $fx="1" $asc><strong>Type</strong></FlexItem>
                <FlexItem $fx="3">
                  <SlRadioGroup value="1">
                    <SlRadioButton value="1">Findings</SlRadioButton>
                    <SlRadioButton value="2">Attack Surface</SlRadioButton>
                    <SlRadioButton value="3">Assets</SlRadioButton>
                    <SlRadioButton value="4">Workspace</SlRadioButton>
                    <SlRadioButton value="5">Scan</SlRadioButton>
                  </SlRadioGroup>
                </FlexItem>
              </FlexRows>
              <SlDivider style={{ '--spacing': '2rem' }} />
              <FlexRows>
                <FlexItem $fx="1" $asc><strong>Information</strong></FlexItem>
                <FlexItem $fx="3">
                  <SlRadioGroup value="2">
                    <SlRadioButton value="1">Summary Report</SlRadioButton>
                    <SlRadioButton value="2">Detailed Report</SlRadioButton>
                  </SlRadioGroup>
                </FlexItem>
              </FlexRows>
              <SlDivider style={{ '--spacing': '2rem' }} />
              <FlexRows>
                <FlexItem $fx="1" $asc><strong>Standard</strong></FlexItem>
                <FlexItem $fx="3">
                  <SlRadioGroup value="1">
                    <SlRadioButton value="1">OWASP</SlRadioButton>
                    <SlRadioButton value="2">HIPAA</SlRadioButton>
                    <SlRadioButton value="3">ISO 27001</SlRadioButton>
                    <SlRadioButton value="4">PCI</SlRadioButton>
                  </SlRadioGroup>
                </FlexItem>
              </FlexRows>
              <SlDivider style={{ '--spacing': '2rem' }} />
              <FlexRows>
                <FlexItem $fx="1" $asc><strong>Format</strong></FlexItem>
                <FlexItem $fx="3">
                  <SlRadioGroup value="1">
                    <SlRadioButton value="1">PDF</SlRadioButton>
                    <SlRadioButton value="2">CSV</SlRadioButton>
                    <SlRadioButton value="3">JSON</SlRadioButton>
                    <SlRadioButton value="4">XML</SlRadioButton>
                  </SlRadioGroup>
                </FlexItem>
              </FlexRows>
              <SlDivider style={{ '--spacing': '2rem' }} />
              <FlexRows>
                <FlexItem $fx="3">
                </FlexItem>
                <FlexItem $fx="1">
                  <SlButton variant="primary">
                    <SlIcon slot="prefix" name="file-earmark-break"></SlIcon>
                    Generate Report
                  </SlButton>
                </FlexItem>
              </FlexRows>
            </FlexCols>
          </SlTabPanel>
          <SlTabPanel name="saved">
            <FlexCols $g15>
              <Panel>
                <FlexCols $g10>
                  <NoData />
                  {reportData.map((item, i) => (
                    <ListItem $bd $pd15 $g20 key={i}>
                      <FlexItem $fx="1.5">
                        <FlexCols>
                          <FlexItem><span style={{ fontSize: "0.9rem" }}>{item}</span></FlexItem>
                          <FlexItem>
                            <small style={{ color: "#909090" }}>Workspaces: 10 | Targets: 10 | Assets: 10 | Findings: 10</small>
                          </FlexItem>
                        </FlexCols>
                      </FlexItem>
                      <SlButtonGroup label="Send">
                        <SlIconButton name="share" label="Share" />
                        <SlIconButton name="envelope-at" label="Email" />
                        <SlIconButton name="download" label="Download" />
                      </SlButtonGroup>
                    </ListItem>
                  ))}
                </FlexCols>
              </Panel>
            </FlexCols>
          </SlTabPanel>
        </SlTabGroup>
        <SelectPeriod open={period} setOpen={setPeriod} />
        <SelectScope open={scope} setOpen={setScope} />
      </PageBody>
    </Page>
  )
}