import { TableView } from "lib/components/table";
import { getScanLogs } from "lib/core/bera";

const columns = [
  {
    field: "time",
    display: "DateTime"
  },
  // {
  //   field: "level",
  //   display: "Level"
  // },
  {
    field: "message",
    display: "Message"
  }
];

const options = {
  checkbox: false,
  search: false
}

export const Notifications = () => {
  const resource = getScanLogs()

  return (
    resource &&
    (<TableView data={{ columns, rows: resource?.data }} options={options} />)
  )
}
