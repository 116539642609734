import { Page, PageHeader, Toolbar, FlexItem } from "lib/core/flex"

import { styled, css } from "styled-components";

import SlOption from '@shoelace-style/shoelace/dist/react/option';
import SlSelect from '@shoelace-style/shoelace/dist/react/select';

import { GradientProgressChart } from "lib/charts/gradientProgress";
import Chart from "react-apexcharts";
import { SplitProgress } from "lib/charts/splitProgress";
import { TrendChart } from "lib/charts/trendChart";
import { DonutChart } from "lib/charts/donutChart"

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const DashboardRowItem = styled.div`
  ${props => props.$hasborder && css`
    border: 1px solid ${({ theme }) => theme.color.border};
  `}
  
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`

const PanelRiskSummary = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.border};
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
  margin: 10px 0px;
`

const PanelRiskScore = styled.div`
  width: 200px;
  height: 175px;
  text-align: center;
  border-radius: 6px;
`

const PanelSummary = styled.div`
  // border: 1px solid ${({ theme }) => theme.color.border};
  height: 140px;
  border-radius: 6px;

  // width: 500px;
  align-items: center;
  flex: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const PanelFindings = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border};
  height: 480px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
  padding: 20px;
  // gap: 20px;
  // align-items: center;
  justify-content: space-between;
`
const PanelTrends = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border};
  height: 480px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
  padding: 20px;
  // gap: 20px;
  // align-items: center;
  justify-content: space-between;
`

const PanelTargets = styled.div`
  // border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 6px;
  padding: 15px;
  flex: 1;
  height: 140px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const PanelAssets = styled.div`
  // border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 6px;
  padding: 15px;
  flex: 1;
  height: 140px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const PanelIssues = styled.div`
  // border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 6px;
  padding: 15px;
  flex: 1;
  height: 140px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const PanelScans = styled.div`
  // border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 6px;
  padding: 15px;
  flex: 1;
  height: 140px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StyledPanelTitle = styled.div`
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
`

const StyledPanelSubTitle = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
`

const StylePanelStat = styled.h1`
  font-size: var(--sl-font-size-x-large);
  color: ${props => props.$color || "inherit"};
`
const StyledPanelProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const data = {
  series1: [{
    name: "Targets",
    data: [{
      x: "2023-10-10",
      y: 20
    }, {
      x: "2023-11-10",
      y: 24
    }, {
      x: "2023-12-10",
      y: 20
    }, {
      x: "2024-01-10",
      y: 18
    }, {
      x: "2024-02-10",
      y: 22
    }, {
      x: "2024-03-10",
      y: 20
    }]
    // data: [20, 24, 20, 18, 22, 20]
  }],

  series2: [{
    name: "Assets",
    data: [{
      x: "2023-10-10",
      y: 20
    }, {
      x: "2023-11-10",
      y: 24
    }, {
      x: "2023-12-10",
      y: 20
    }, {
      x: "2024-01-10",
      y: 18
    }, {
      x: "2024-02-10",
      y: 22
    }, {
      x: "2024-03-10",
      y: 20
    }]
    // data: [20, 24, 20, 18, 22, 20]
  }],

  series3: [{
    name: "Open Issues",
    data: [{
      x: "2023-10-10",
      y: 34
    }, {
      x: "2023-11-10",
      y: 41
    }, {
      x: "2023-12-10",
      y: 49
    }, {
      x: "2024-01-10",
      y: 55
    }, {
      x: "2024-02-10",
      y: 58
    }, {
      x: "2024-03-10",
      y: 41
    }]
    // data: [20, 24, 20, 18, 22, 20]
  }],

  series4: [{
    name: "Scans",
    data: [{
      x: "2023-10-10",
      y: 20
    }, {
      x: "2023-11-10",
      y: 24
    }, {
      x: "2023-12-10",
      y: 20
    }, {
      x: "2024-01-10",
      y: 18
    }, {
      x: "2024-02-10",
      y: 22
    }, {
      x: "2024-03-10",
      y: 20
    }]
    // data: [20, 24, 20, 18, 22, 20]
  }],

  options: {
    chart: {
      type: 'line',
      sparkline: {
        enabled: true
      },
    },
    stroke: {
      curve: 'straight',
      width: 1
    },
    fill: {
      opacity: 0,
    },
    colors: ['#DCE6EC'],
  }
}

export default function Dashboard2() {
  return (
    <Page $g15>
      <PageHeader>
        <FlexItem>Dashboard</FlexItem>
        <Toolbar>
          <SlSelect value="option-0">
            <SlOption value="option-0">All Workspaces</SlOption>
            <SlOption value="option-1">ws-blogs</SlOption>
            <SlOption value="option-2">ws-customer support</SlOption>
            <SlOption value="option-3">ws-admin dev</SlOption>
            <SlOption value="option-4">ws-admin prod</SlOption>
          </SlSelect>
        </Toolbar>
      </PageHeader>
      <DashboardContainer>
        <DashboardRowItem>
          <PanelRiskSummary>
            <PanelRiskScore><GradientProgressChart /></PanelRiskScore>
            <PanelSummary>
              <PanelTargets>
                <StyledPanelSubTitle>Targets</StyledPanelSubTitle>
                <StylePanelStat>20</StylePanelStat>
                <Chart options={data.options} series={data.series1} type="area" height={30} />
              </PanelTargets>
              <PanelAssets>
                <StyledPanelSubTitle>Assets</StyledPanelSubTitle>
                <StylePanelStat>12</StylePanelStat>
                <Chart options={data.options} series={data.series2} type="area" height={30} />
              </PanelAssets>
              <PanelIssues>
                <StyledPanelSubTitle>Findings</StyledPanelSubTitle>
                <StylePanelStat>41</StylePanelStat>
                <Chart options={data.options} series={data.series3} type="area" height={30} />
              </PanelIssues>
              <PanelScans>
                <StyledPanelSubTitle>Scans</StyledPanelSubTitle>
                <StylePanelStat>12</StylePanelStat>
                <Chart options={data.options} series={data.series4} type="area" height={30} />
              </PanelScans>
            </PanelSummary>
          </PanelRiskSummary>
        </DashboardRowItem>
        <DashboardRowItem>
          <PanelFindings>
            <StyledPanelTitle>Findings</StyledPanelTitle>
            <StyledPanelSubTitle>Severity</StyledPanelSubTitle>
            <StyledPanelProgress><SplitProgress progressValues={[40, 25, 25, 10]} /></StyledPanelProgress>
            <StyledPanelSubTitle>Category</StyledPanelSubTitle>
            <StyledPanelRow>
              <DonutChart />
            </StyledPanelRow>
          </PanelFindings>
          <PanelTrends>
            <StyledPanelTitle>Vulnerabilities Trend</StyledPanelTitle>
            <TrendChart />
          </PanelTrends>
        </DashboardRowItem>
      </DashboardContainer>
    </Page>

  )
}
