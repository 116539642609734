
import useSessionData from "stores/useSessionData";
import { FlexCols, FlexItem, FlexRows } from "lib/core/flex";
import SlProgressBar from '@shoelace-style/shoelace/dist/react/progress-bar';
import { GradientProgressChart } from "lib/charts/gradientProgress";

function SeverityLayout(props) {
  var total = props.c + props.h + props.m + props.l;
  return (
    <FlexCols $g20 style={{ fontSize: "0.75rem" }}>
      <FlexRows></FlexRows>
      <FlexRows $g20 $aic>
        <FlexItem $fx="1">Critical</FlexItem>
        <FlexItem $fx="6"><SlProgressBar className="dashboard" value={total > 0 ? (props.c * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#AA281E' }} /></FlexItem>
        <FlexItem $fx="0.3"><strong>{props.c}</strong></FlexItem>
      </FlexRows>
      <FlexRows $g20 $aic>
        <FlexItem $fx="1">High</FlexItem>
        <FlexItem $fx="6"><SlProgressBar value={total > 0 ? (props.h * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#DC5F23' }} /></FlexItem>
        <FlexItem $fx="0.3"><strong>{props.h}</strong></FlexItem>
      </FlexRows>
      <FlexRows $g20 $aic>
        <FlexItem $fx="1">Medium</FlexItem>
        <FlexItem $fx="6"><SlProgressBar value={total > 0 ? (props.m * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#F0963C' }} /></FlexItem>
        <FlexItem $fx="0.3"><strong>{props.m}</strong></FlexItem>
      </FlexRows>
      <FlexRows $g20 $aic>
        <FlexItem $fx="1">Low</FlexItem>
        <FlexItem $fx="6"><SlProgressBar value={total > 0 ? (props.l * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#FACD41' }} /></FlexItem>
        <FlexItem $fx="0.3"><strong>{props.l}</strong></FlexItem>
      </FlexRows>
    </FlexCols>
  )
}

export const RiskScore = () => {
  var args = {
    slice_name: "stat",
    store_name: "severity"
  }

  var resource = useSessionData(args)

  return (
    resource && <GradientProgressChart value={resource?.data?.score} />
  )
}

export const Severity = () => {
  var args = {
    slice_name: "stat",
    store_name: "severity"
  }

  var resource = useSessionData(args)

  return (
    resource && <SeverityLayout c={resource?.data?.critical} h={resource?.data?.high} m={resource?.data?.medium} l={resource?.data?.low} />
  )
}
