import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';

import { FlexCols, FlexItem, FlexRows } from 'lib/core/flex';
import { GradientProgressChart } from "lib/charts/gradientProgress";
import SlProgressBar from '@shoelace-style/shoelace/dist/react/progress-bar';
import { TrendChart } from 'lib/charts/trendChart';
import { TableView } from "lib/components/table";

import { DataSafeComponent, NoData } from 'routes/main';
import {
  getScanList,
  getScanAssetIPs,
  getScanAssetCIDR,
  // getScanAssetDiscover,
  getScanAssetASN,
  getSubdomains,
  getSubdomainVulnerabilities,
  getSubdomainWeb,
  getSubdomainOSINT,
  getSubdomainNMAP
} from "lib/core/bera";


const SubTitle = styled.h3`
  display: inline-block;
  padding-top: 10px;
`

const StyledButtonGroupToolbar = styled.div`
  :not(:last-of-type) {
      margin-right: var(--sl-spacing-x-small);
  }
`

const Panel = styled.div`
  margin: -10px -10px;
  padding: 10px;
  // border: 1px solid black;
`

const ListItem = styled(FlexRows)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;
  min-height: 60px;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`

const Panel2 = styled(FlexItem).attrs({
})`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
 
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const PanelOuter = styled(FlexItem).attrs({
})`
  display: flex;
  // flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
 
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const StyledBadge = styled.span`
    padding: 2px 6px;
    // border: 1px solid #909090;
    background-color: ${({ theme }) => theme.color.textFill};
    border-radius: 4px;
`

const PanelTitle = styled(FlexItem).attrs({
})`
  font-weight: 600;
`

const Label = styled.h4``
const Text = styled.span``

export const WorkspaceContent = (props) => {
  const [view, setView] = useState("overview")
  const [latestScan, selectedIndex, workspaceCount] = props.states
  const [handleBack, clickPrev, clickNext] = props.handlers

  function WorkspaceOverview() {

    function SeverityLayout(props) {
      var total = props.c + props.h + props.m + props.l;
      return (
        <FlexCols $g20 style={{ fontSize: "0.75rem" }}>
          <FlexRows></FlexRows>
          <FlexRows $g20 $aic>
            <FlexItem $fx="1">Critical</FlexItem>
            <FlexItem $fx="6"><SlProgressBar className="dashboard" value={total > 0 ? (props.c * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#AA281E' }} /></FlexItem>
            <FlexItem $fx="0.3"><strong>{props.c}</strong></FlexItem>
          </FlexRows>
          <FlexRows $g20 $aic>
            <FlexItem $fx="1">High</FlexItem>
            <FlexItem $fx="6"><SlProgressBar value={total > 0 ? (props.h * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#DC5F23' }} /></FlexItem>
            <FlexItem $fx="0.3"><strong>{props.h}</strong></FlexItem>
          </FlexRows>
          <FlexRows $g20 $aic>
            <FlexItem $fx="1">Medium</FlexItem>
            <FlexItem $fx="6"><SlProgressBar value={total > 0 ? (props.m * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#F0963C' }} /></FlexItem>
            <FlexItem $fx="0.3"><strong>{props.m}</strong></FlexItem>
          </FlexRows>
          <FlexRows $g20 $aic>
            <FlexItem $fx="1">Low</FlexItem>
            <FlexItem $fx="6"><SlProgressBar value={total > 0 ? (props.l * 100 / total) : 0} style={{ '--height': '6px', '--indicator-color': '#FACD41' }} /></FlexItem>
            <FlexItem $fx="0.3"><strong>{props.l}</strong></FlexItem>
          </FlexRows>
        </FlexCols>
      )
    }

    const series = [{
      name: 'Findings',
      data: []
    }];

    const categories = []

    props?.data?.trend?.map((item) => {
      categories.push(item.period)
      series[0].data.push(item.val)
    })

    return (
      <>
        <FlexCols $g20>
          <FlexRows $g20 style={{ height: "215px" }}>
            <PanelOuter $fx="4">
              <Panel2 $fx="1">
                <PanelTitle>Risk score</PanelTitle>
                <SlDivider />
                <GradientProgressChart value={props?.data?.severity_score.score} />
              </Panel2>
              <Panel2 $fx="3">
                <PanelTitle>Severity</PanelTitle>
                <SlDivider />
                <SeverityLayout c={props?.data?.severity_score.critical}
                  h={props?.data?.severity_score.high}
                  m={props?.data?.severity_score.medium}
                  l={props?.data?.severity_score.low} />
              </Panel2>
            </PanelOuter>
          </FlexRows>
          <FlexRows>
            <PanelOuter $fx="2">
              <Panel2 $fx="2">
                <PanelTitle>Trend</PanelTitle>
                <SlDivider />
                {/* Latest scan: {latestScan}<br /> */}
                {/* Trend: {props?.data?.trend} */}
                <TrendChart series={series} categories={categories} />
              </Panel2>
            </PanelOuter>
          </FlexRows>
        </FlexCols>
      </>
    )
  }

  function WorkspaceScans(props) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const args = { "path": { "wid": props.wid } }
    const resource = getScanList(args)

    function ItemBlock(props) {
      return (
        <FlexItem $fx="1.5">
          <FlexCols>
            <FlexItem>
              <span style={{ fontSize: "0.9rem" }}>
                {
                  props?.item?.status === "SCAN_PASSED" && (
                    <span style={{ color: 'var(--sl-color-success-600)' }}>
                      <SlIcon name="check-circle"></SlIcon>
                    </span>
                  )
                }
                {
                  props?.item?.status !== "SCAN_PASSED" && (
                    <span style={{ color: 'var(--sl-color-danger-600)' }}>
                      <SlIcon name="exclamation-circle"></SlIcon>
                    </span>
                  )
                }
                &nbsp;&nbsp;
                {
                  props?.item?.start_time.substring(0, 10)
                }
              </span>
            </FlexItem>
            <FlexItem>
              <small style={{ color: "#909090" }}>
                Risk score <StyledBadge>{props?.item?.severity_score?.score}</StyledBadge>&nbsp;
                Findings <StyledBadge>{getSeverityTotal(props?.item)}</StyledBadge>
              </small>
            </FlexItem>
          </FlexCols>
        </FlexItem>
      )
    }


    function ScanInfo() {

      return (
        <>
          {
            resource?.data && (
              <FlexCols $g10>
                <h3>Scan info</h3>
                <SlDivider />
                <FlexRows>
                  <FlexItem $fx="1"><Label>Start time</Label></FlexItem>
                  <FlexItem $fx="1"><Label>Finish time</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.start_time}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.finish_time}</Text></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Label>Durarion</Label></FlexItem>
                  <FlexItem $fx="1"><Label>Status</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.duration}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.status.replace("SCAN_", "")}</Text></FlexItem>
                </FlexRows>
                <div style={{ height: "10px" }}></div>
                <h3>Findings</h3>
                <SlDivider />
                <FlexRows>
                  <FlexItem $fx="1"><Label>Risk Score</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.severity_score.score}</Text></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Label>Critical</Label></FlexItem>
                  <FlexItem $fx="1"><Label>High</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.severity_score.critical}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.severity_score.high}</Text></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Label>Medium</Label></FlexItem>
                  <FlexItem $fx="1"><Label>Low</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.severity_score.medium}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.severity_score.low}</Text></FlexItem>
                </FlexRows>
                <div style={{ height: "10px" }}></div>
                <h3>Assets</h3>
                <SlDivider />
                <FlexRows>
                  <FlexItem $fx="1"><Label>Subdomains</Label></FlexItem>
                  <FlexItem $fx="1"><Label>IPs</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.assets.sub_domains}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.assets.ips}</Text></FlexItem>
                </FlexRows>
                <div style={{ height: "10px" }}></div>
                <h3>Attack surface</h3>
                <SlDivider />
                <FlexRows>
                  <FlexItem $fx="1"><Label>Open ports</Label></FlexItem>
                  <FlexItem $fx="1"><Label>Expired certificates</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.assets.ports}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.assets.expired_certs}</Text></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Label>Subdomain takeover</Label></FlexItem>
                  <FlexItem $fx="1"><Label>SSL issues</Label></FlexItem>
                </FlexRows>
                <FlexRows>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.assets.takeovers}</Text></FlexItem>
                  <FlexItem $fx="1"><Text>{resource?.data[selected]?.assets.ssl}</Text></FlexItem>
                </FlexRows>
              </FlexCols>
            )
          }
        </>
      )
    }

    function getSeverityTotal(item) {
      return item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low
    }

    return (
      <>
        <FlexCols $g15>
          <Panel>
            {
              <FlexCols $g10>
                {
                  resource && resource.data.map((item, i) => (
                    <ListItem $bd $pd15 $g20 key={i} onClick={() => { setSelected(i); setOpen(true) }}>
                      <FlexItem $fx="1.5"><ItemBlock item={item} /></FlexItem>
                      <FlexItem $asfe fx="0.2"><SlIcon name="clock"></SlIcon>&nbsp;&nbsp;{item.duration}</FlexItem>
                    </ListItem>
                  ))
                }
              </FlexCols>
            }
          </Panel>
          <SlDrawer label={"Scan " + resource?.data[selected]?.start_time.substring(0, 10)} open={open} onSlAfterHide={() => setOpen(false)}>
            <ScanInfo />
            <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
              Close
            </SlButton>
          </SlDrawer>
        </FlexCols>
      </>
    )
  }

  function WorkspaceSubdomains(props) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(0);
    const [subdomains, setSubdomains] = useState(null)

    useEffect(() => {
      const args = { "path": { "wid": props.wid, "sid": latestScan } }

      getSubdomains(args).then((res) => {
        setSubdomains(res?.data)
      })
    }, [props.wid, latestScan])


    function WorkspaceSubdomainFindings(props) {
      const args = { "path": { "wid": props.wid, "sid": props.sid, "sdid": props.sdid } }
      const resource = getSubdomainVulnerabilities(args)

      const columns = [
        {
          field: "title",
          display: "Title"
        },
        {
          field: "severity",
          display: "Severity"
        },
        {
          field: "score",
          display: "Score"
        },
        // {
        //   field: "desc",
        //   display: "Description"
        // },
        // {
        //   field: "ref",
        //   display: "Reference"
        // }
      ];

      if (resource?.data === null) {
        return <NoData />
      }

      if (resource?.data?.items && !Object.keys(resource?.data?.items).length) {
        return <NoData />
      }

      return (
        resource && <TableView options={{ checkbox: false, ipp: false, tooltip: false, defIpp: 5 }}
          data={{ columns, rows: resource?.data?.items }}></TableView>
      )
    }

    function WorkspaceSubdomainWeb(props) {
      const args = { "path": { "wid": props.wid, "sid": props.sid, "sdid": props.sdid } }
      const resource = getSubdomainWeb(args)

      const col1 = [
        {
          field: "name",
          display: "SNI"
        },
        {
          field: "port",
          display: "Port"
        },
      ];

      const col2 = [
        {
          field: "protocol",
          display: "Protocol"
        },
        {
          field: "support",
          display: "Support"
        },
      ];

      const col3 = [
        {
          field: "name",
          display: "WAF"
        },
        {
          field: "url",
          display: "Url"
        },
      ];

      function getProtocols(items) {
        const protocols = []
        items?.map((item) => (
          Object.entries(item)?.map(([key, value]) => (
            protocols.push({ protocol: key, support: value })
          ))
        ))
        return protocols
      }

      if (resource?.data && !Object.keys(resource?.data).length) {
        return <NoData />
      }

      return (
        resource && <FlexCols $g10>
          {
            <>
              {
                resource?.data?.info && (
                  <>
                    <h3>Server info</h3>
                    <SlDivider />
                    <FlexRows>
                      <FlexItem $fx="1"><Label>IP</Label></FlexItem>
                      <FlexItem $fx="1"><Label>Country</Label></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Text>{resource?.data?.info?.ip?.join(", ")}</Text></FlexItem>
                      <FlexItem $fx="1"><Text>{resource?.data?.info?.country}</Text></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Label>Server</Label></FlexItem>
                      <FlexItem $fx="1"><Label>Technology</Label></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Text>{resource?.data?.info?.server}</Text></FlexItem>
                      <FlexItem $fx="1"><Text>{resource?.data?.info?.tech.join(", ")}</Text></FlexItem>
                    </FlexRows>
                  </>
                )
              }
              {
                resource?.data?.ssl && (
                  <>
                    <h3>SSL info</h3>
                    <SlDivider />
                    <FlexRows>
                      <FlexItem $fx="1"><Label>Valid from</Label></FlexItem>
                      <FlexItem $fx="1"><Label>Valid to</Label></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Text>{resource?.data?.ssl?.valid_from}</Text></FlexItem>
                      <FlexItem $fx="1"><Text>{resource?.data?.ssl?.valid_to}</Text></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Label>Issuer</Label></FlexItem>
                      <FlexItem $fx="1"><Label>Heartbleed</Label></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Text>{resource?.data?.ssl?.issuer}</Text></FlexItem>
                      <FlexItem $fx="1"><Text>{resource?.data?.ssl?.heartbleed.join(", ")}</Text></FlexItem>
                    </FlexRows>
                    <FlexItem>
                      <TableView options={{ checkbox: false, ipp: false }}
                        data={{ columns: col2, rows: getProtocols(resource?.data?.ssl?.protocols) }}>
                      </TableView>
                    </FlexItem>
                    <FlexItem><TableView options={{ checkbox: false, ipp: false }}
                      data={{ columns: col1, rows: resource?.data?.ssl?.sni }}></TableView>
                    </FlexItem>
                  </>
                )
              }
              {
                resource?.data?.waf && (
                  <>
                    <FlexItem><TableView options={{ checkbox: false, ipp: false }}
                      data={{ columns: col3, rows: resource?.data?.waf }}></TableView>
                    </FlexItem>
                  </>
                )
              }
              {
                resource?.data?.tech && (
                  <>
                    <FlexRows>
                      <FlexItem $fx="1"><Label>Http Tech</Label></FlexItem>
                      <FlexItem $fx="1"><Label>Https Tech</Label></FlexItem>
                    </FlexRows>
                    <FlexRows>
                      <FlexItem $fx="1"><Text>{resource?.data?.tech?.http.join(", ")}</Text></FlexItem>
                      <FlexItem $fx="1"><Text>{resource?.data?.tech?.https.join(", ")}</Text></FlexItem>
                    </FlexRows>
                  </>
                )
              }
            </>
          }
        </FlexCols>
      )
    }

    function WorkspaceSubdomainOsint(props) {
      const args = { "path": { "wid": props.wid, "sid": props.sid, "sdid": props.sdid } }
      const resource = getSubdomainOSINT(args)

      if (resource?.data?.items && !Object.keys(resource?.data?.items).length) {
        return <NoData />
      }

      return (
        (resource && resource?.data?.items) && (
          <FlexCols>
            {
              Object.entries(resource?.data?.items).map(([key, value]) => (
                <FlexRows key={key} $pd5>
                  <FlexItem $fx="1"><Label>{key.replaceAll("_", " ")}</Label></FlexItem>
                  <FlexItem $fx="1"><Text>{value}</Text></FlexItem>
                </FlexRows>
              ))
            }
          </FlexCols >
        )
      )
    }

    function WorkspaceSubdomainNmap(props) {
      const args = { "path": { "wid": props.wid, "sid": props.sid, "sdid": props.sdid } }
      const resource = getSubdomainNMAP(args)

      const col1 = [
        {
          field: "port",
          display: "Port"
        },
        {
          field: "protocol",
          display: "Protocol"
        },
      ];

      const col2 = [
        {
          field: "name",
          display: "Hostname"
        },
        {
          field: "type",
          display: "Type"
        },
      ];

      if (resource?.data && !Object.keys(resource?.data).length) {
        return <NoData />
      }

      return (
        (resource && resource?.data) && (
          <FlexCols $g10>
            <h3>Network info</h3>
            <SlDivider />
            <FlexRows>
              <FlexItem $fx="1"><Label>Address</Label></FlexItem>
              <FlexItem $fx="1"><Label>Type</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{resource?.data?.address?.value}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{resource?.data?.address?.type?.toUpperCase()}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Status</Label></FlexItem>
              <FlexItem $fx="1"><Label>DNS</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{resource?.data?.status}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{resource?.data?.dns?.join(", ")}</Text></FlexItem>
            </FlexRows>
            <FlexItem><TableView options={{ checkbox: false, ipp: false }}
              data={{ columns: col2, rows: resource?.data?.hostnames }}></TableView>
            </FlexItem>
            <FlexItem><TableView options={{ checkbox: false, ipp: false, defIpp: 5 }}
              data={{ columns: col1, rows: resource?.data?.ports }}></TableView>
            </FlexItem>
          </FlexCols>
        )
      )
    }

    function WorkspaceSubdomainContent() {
      return (
        <SlTabGroup>
          <SlTab slot="nav" panel="findings">
            Findings
          </SlTab>
          <SlTab slot="nav" panel="web">
            Web
          </SlTab>
          <SlTab slot="nav" panel="nmap">
            Network
          </SlTab>
          <SlTab slot="nav" panel="osint">
            OSINT
          </SlTab>

          <SlTabPanel name="findings">
            <DataSafeComponent>
              <WorkspaceSubdomainFindings wid={props.wid} sid={latestScan} sdid={subdomains[selected]} />
            </DataSafeComponent>
          </SlTabPanel>
          <SlTabPanel name="web">
            <DataSafeComponent>
              <WorkspaceSubdomainWeb wid={props.wid} sid={latestScan} sdid={subdomains[selected]} />
            </DataSafeComponent>
          </SlTabPanel>
          <SlTabPanel name="nmap">
            <DataSafeComponent>
              <WorkspaceSubdomainNmap wid={props.wid} sid={latestScan} sdid={subdomains[selected]} />
            </DataSafeComponent>
          </SlTabPanel>
          <SlTabPanel name="osint">
            <DataSafeComponent>
              <WorkspaceSubdomainOsint wid={props.wid} sid={latestScan} sdid={subdomains[selected]} />
            </DataSafeComponent>
          </SlTabPanel>
        </SlTabGroup>
      )
    }

    function clickPrev(e) {
      e.preventDefault()

      if (subdomains && selected > 0) {
        var prevIdx = selected - 1
        setSelected(prevIdx)
      }
    }

    function clickNext(e) {
      e.preventDefault()

      if (subdomains && ((selected + 1) < subdomains?.length)) {
        var nextIdx = selected + 1
        setSelected(nextIdx)
      }
    }

    return (
      <>
        {
          subdomains && <FlexCols $g15>
            <Panel>
              {
                <FlexCols $g10>
                  {
                    subdomains.map((item, i) => (
                      <ListItem $bd $pd15 $g20 key={i} onClick={() => { setSelected(i); setOpen(true) }}>
                        <FlexItem>{item}</FlexItem>
                      </ListItem>
                    ))
                  }
                </FlexCols>
              }
            </Panel>
            <SlDrawer label={subdomains[selected]} open={open} onSlAfterHide={() => setOpen(false)} style={{ '--size': '60vw' }}>
              <span slot="header-actions">
                {selected + 1} of {subdomains?.length}
                <SlButtonGroup style={{ margin: "15px" }}>
                  <SlButton size="medium" onClick={(e) => clickPrev(e)}>
                    <SlIcon name="arrow-left"></SlIcon>
                  </SlButton>
                  <SlButton size="medium" onClick={(e) => clickNext(e)}>
                    <SlIcon name="arrow-right"></SlIcon>
                  </SlButton>
                </SlButtonGroup>
              </span>
              <WorkspaceSubdomainContent />
              <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
                Close
              </SlButton>
            </SlDrawer>
          </FlexCols>
        }
      </>
    )
  }

  function WorkspaceIPs(props) {
    const args = { "path": { "wid": props.wid, "sid": latestScan } }
    const resource = getScanAssetIPs(args)

    const columns = [
      {
        field: "ip",
        display: "IP Address"
      }
    ];

    function getRows(data) {
      const rowData = []

      if (data) {
        data.map((item) => {
          rowData.push({ "ip": item })
        })
      }
      return rowData
    }

    return (
      <>
        {
          (resource && resource?.data?.ips) && <TableView options={{ checkbox: false, ipp: true }}
            data={{ columns, rows: getRows(resource?.data?.ips) }}></TableView>
        }
        {
          <NoData criteria={resource?.data?.ips === null} />
        }
      </>
    )
  }

  function WorkspaceCIDR(props) {
    const args = { "path": { "wid": props.wid, "sid": latestScan } }
    const resource = getScanAssetCIDR(args)

    const columns = [
      {
        field: "range",
        display: "Range"
      }
    ];

    function getRows(data) {
      const rowData = []

      if (data) {
        data.map((item) => {
          rowData.push({ "range": item })
        })
      }

      return rowData
    }

    return (
      <>
        {
          (resource && resource?.data?.cidr) && (
            <SlTabGroup>
              {
                resource?.data?.cidr?.map((item, i) => (
                  <>
                    <SlTab slot="nav" panel={"panel-" + i}>
                      {item.name}
                    </SlTab>
                    <SlTabPanel name={"panel-" + i}>
                      <TableView options={{ checkbox: false, ipp: true }}
                        data={{ columns, rows: getRows(item?.ranges) }}></TableView>
                    </SlTabPanel>
                  </>
                ))
              }
            </SlTabGroup>
          )
        }
        {
          <NoData criteria={resource?.data?.cidr === null} />
        }
      </>
    )
  }

  function WorkspaceASN(props) {
    const args = { "path": { "wid": props.wid, "sid": latestScan } }
    const resource = getScanAssetASN(args)

    return (
      <>
        {
          (resource && resource?.data?.asn) && (
            <SlTabGroup>
              {
                resource?.data?.asn?.map((item, i) => (
                  <>
                    <SlTab slot="nav" panel={"panel-" + i}>
                      {item.name}
                    </SlTab>
                    <SlTabPanel name={"panel-" + i}>
                      <FlexCols $g10>
                        <FlexItem $fx="1"><Label>ASN</Label></FlexItem>
                        <FlexItem $fx="1"><Text>{item.info.asn}</Text></FlexItem>
                        <FlexItem $fx="1"><Label>ORG</Label></FlexItem>
                        <FlexItem $fx="1"><Text>{item.info.org}</Text></FlexItem>
                        <FlexItem $fx="1"><Label>IPs</Label></FlexItem>
                        <FlexItem $fx="1"><Text>{item.info.ips}</Text></FlexItem>
                        <FlexItem $fx="1"><Label>CIDRs</Label></FlexItem>
                        <FlexItem $fx="1"><Text>{item.info.cidrs}</Text></FlexItem>
                      </FlexCols>
                    </SlTabPanel>
                  </>
                ))
              }
            </SlTabGroup>
          )
        }
        {
          <NoData criteria={resource?.data?.asn === null} />
        }
      </>
    )
  }

  function WorkspaceSubContent() {
    const wid = props?.data?.id

    const collection = {
      overview: (<DataSafeComponent>
        <WorkspaceOverview wid={wid} />
      </DataSafeComponent>),
      scans: (<DataSafeComponent>
        <WorkspaceScans wid={wid} />
      </DataSafeComponent>),
      subdomains: (<DataSafeComponent>
        <WorkspaceSubdomains wid={wid} />
      </DataSafeComponent>),
      ips: (<DataSafeComponent>
        <WorkspaceIPs wid={wid} />
      </DataSafeComponent>),
      cidr: (<DataSafeComponent>
        <WorkspaceCIDR wid={wid} />
      </DataSafeComponent>),
      asn: (<DataSafeComponent>
        <WorkspaceASN wid={wid} />
      </DataSafeComponent>)
    }

    return collection[view]
  }

  function WorkspaceToolbar() {
    return (
      <StyledButtonGroupToolbar>
        <SlButtonGroup>
          <SlTooltip content="Overview">
            <SlButton variant={view == "overview" ? "primary" : "default" } onClick={() => { setView("overview") }}>
              <SlIcon name="layout-wtf"></SlIcon>
            </SlButton>
          </SlTooltip>
          <SlTooltip content="Scans">
            <SlButton variant={view == "scans" ? "primary" : "default"} onClick={() => { setView("scans") }}>
              <SlIcon name="clock-history"></SlIcon>
            </SlButton>
          </SlTooltip>
        </SlButtonGroup>
        <SlButtonGroup>
          <SlTooltip content="Subdomains">
            <SlButton variant={view == "subdomains" ? "primary" : "default"} onClick={() => { setView("subdomains") }}>
              <SlIcon name="globe2"></SlIcon>
            </SlButton>
          </SlTooltip>
          <SlTooltip content="IPs">
            <SlButton variant={view == "ips" ? "primary" : "default"} onClick={() => { setView("ips") }}>
              <SlIcon name="geo-alt"></SlIcon>
            </SlButton>
          </SlTooltip>
          <SlTooltip content="CIDR">
            <SlButton variant={view == "cidr" ? "primary" : "default"} onClick={() => { setView("cidr") }}>
              <SlIcon name="hexagon"></SlIcon>
            </SlButton>
          </SlTooltip>
          <SlTooltip content="ASN">
            <SlButton variant={view == "asn" ? "primary" : "default"} onClick={() => { setView("asn") }}>
              <SlIcon name="card-heading"></SlIcon>
            </SlButton>
          </SlTooltip>
        </SlButtonGroup>
      </StyledButtonGroupToolbar>
    )
  }

  function WorkspaceNavbar() {
    return (
      <span>
        {selectedIndex + 1} of {workspaceCount}
        <SlButtonGroup style={{ paddingLeft: "15px" }}>
          <SlButton size="medium" onClick={(e) => { clickPrev(e) }}>
            <SlIcon name="arrow-left"></SlIcon>
          </SlButton>
          <SlButton size="medium" onClick={(e) => { clickNext(e) }}>
            <SlIcon name="arrow-right"></SlIcon>
          </SlButton>
        </SlButtonGroup>
      </span>
    )
  }

  return (
    <>
      <FlexCols $g10>
        <FlexRows $jcsb>
          {
            props.backbtn && <FlexItem $fx="1"><SlButton onClick={(e) => { handleBack(e) }}>
              <SlIcon name="chevron-left"></SlIcon></SlButton></FlexItem>
          }
          <FlexItem $fx="2"><SubTitle>{props?.data?.name}</SubTitle></FlexItem>
          <FlexItem $fx="2"><WorkspaceToolbar /></FlexItem>
          <FlexItem><WorkspaceNavbar /></FlexItem>
        </FlexRows>

        <SlDivider />
        <WorkspaceSubContent />
      </FlexCols>
    </>
  )
}
