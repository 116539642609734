import { Navigate, Outlet } from "react-router-dom";
import { GridLayout, NavigationMenu, Sidebar, Page, Footer } from "lib/layout";
import { NavigationMenuItems } from "lib/menu/navItems";
import { SidebarMenuItems } from "lib/menu/sidebarItems";
import { useState, useEffect } from 'react';
import { GetToken, ValidateToken, RefreshSession } from "lib/core/localstore";

import { GUI_SIGNIN } from "lib/core/gui_endpoints"

export function Index() {

  const [token, setToken] = useState(GetToken() !== null);

  useEffect(() => {
    (async () => {
      const result = await ValidateToken();
      setToken(result);
    })();
  }, []);

  useEffect(() => {
    RefreshSession();
  }, [token]);

  return token ? (
    <GridLayout>
      <NavigationMenu>
        <NavigationMenuItems />
      </NavigationMenu>
      <Sidebar>
        <SidebarMenuItems />
      </Sidebar>
      <Page><Outlet /></Page>
      <Footer>© 2024 CloudMatos.ai</Footer>
    </GridLayout>
  ) : <Navigate to={GUI_SIGNIN} />;
}