import Chart from "react-apexcharts";

export default function DonutChart2(props) {
  const options = {
    chart: {
      type: 'pie',
    },
    labels: ["Critical", "High", "Medium", "Low"],
    theme: {
      monochrome: {
        enabled: true
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5
        }
      }
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        return [name, val.toFixed(1) + '%']
      }
    },
    legend: {
      show: false
    }
  }

  return (
    <>
      <Chart options={options} series={props.series} type="donut" width={320} />
    </>
  )
}