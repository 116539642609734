import useSessionData from "stores/useSessionData";
import { FlexCols, FlexItem } from "lib/core/flex";
import styled from "styled-components";

const StyledH1 = styled.h1`
  font-size: var(--sl-font-size-2x-large);
`

export const SummaryAttackSurface = () => {
  var args = {
    slice_name: "asset2",
    store_name: "overview"
  }

  var resource = useSessionData(args)

  return (
    <FlexCols $g10>
      <FlexItem></FlexItem>
      {
        resource &&
        (
          <>
            <FlexItem $asc>Open Ports</FlexItem>
            <FlexItem $asc><StyledH1>{resource?.data?.ports}</StyledH1></FlexItem>
            <FlexItem></FlexItem>
            <FlexItem $asc>Expired Certificates</FlexItem>
            <FlexItem $asc><StyledH1>{resource?.data?.expired_certs}</StyledH1></FlexItem>
          </>
        )
      }
    </FlexCols>
  )
}

export const SummaryAssets = () => {
  var args = {
    slice_name: "asset2",
    store_name: "overview"
  }

  var resource = useSessionData(args)

  return (
    <FlexCols $g10>
      <FlexItem></FlexItem>
      {
        resource &&
        (
          <>
            <FlexItem $asc>Domains</FlexItem>
            <FlexItem $asc><StyledH1>{resource?.data?.domains}</StyledH1></FlexItem>
            <FlexItem></FlexItem>
            <FlexItem $asc>Subdomains</FlexItem>
            <FlexItem $asc><StyledH1>{resource?.data?.sub_domains}</StyledH1></FlexItem>
          </>
        )
      }
    </FlexCols>
  )
}