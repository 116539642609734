// bera: backend rest api's
import { useState, useEffect } from 'react';
import axios from 'axios';
import GetSecurityHeader from 'lib/core/security_header';

const URL_BASE = 'https://api.cloudmatos.ai'

const URL_SIGNIN = URL_BASE + '/auth/v1/signin'
const URL_SIGNOUT = URL_BASE + '/auth/v1/signout'
const URL_BEAT = URL_BASE + '/auth/v1/beat'

const promiseWrapper = (promise) => {
  let status = "pending";
  let result;

  const s = promise.then(
    (value) => {
      status = "success";
      result = value;
    },
    (error) => {
      status = "error";
      result = error;
    }
  );

  return () => {
    switch (status) {
    case "pending":
      throw s;
    case "success":
      return result;
    case "error":
      throw result;
    default:
      throw new Error("Unknown status");
    }
  };
};

function httpRequest(args) {
  const [resource, setResource] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const promise = axios({
        ...args,
        baseURL: URL_BASE,
        headers: GetSecurityHeader()
      }).then((response) => response.data)
        .catch((error) => {
          if (error.response.status == 401) {
            // "Access Unauthorized!"
          }
        })

      setResource(promiseWrapper(promise));
    };

    getData();
  }, []);

  return resource;
}

export async function loginUser(data) {
  return axios.post(URL_SIGNIN, data)
    .then(res => {
      return res;
    }).catch(err => {
      return err
    })
}

export async function logoutUser() {
  return axios.post(URL_SIGNOUT, null, {
    headers: GetSecurityHeader()
  })
    .then(res => {
      return res;
    })
    .catch(() => { });
}

export function heartBeat() {
  return axios.head(URL_BEAT, {
    headers: GetSecurityHeader()
  })
    .then(() => { return true })
    .catch(() => { return false });
}

export async function createUser(data) {
  return httpRequest({
    method: 'post',
    url: "/auth/v1/signup",
    data: data
  })
}

export function getUserList() {
  return httpRequest({
    method: 'get',
    url: "/console/v1/users"
  })
}

export async function createWorkspace(data) {
  return axios.post(URL_BASE + "/console/v1/workspace",
    data, {
      headers: GetSecurityHeader()
    })
    .then(res => {
      return res;
    })
}

export function getWorkspaceList() {
  return httpRequest({
    method: 'get',
    url: "/console/v1/workspace"
  })
}

export function getAccessLogs() {
  return httpRequest({
    method: 'get',
    url: "/console/v1/logs/access"
  })
}

export function getApplicationLogs() {
  return httpRequest({
    method: 'get',
    url: "/console/v1/logs/application"
  })
}

export function getScanLogs() {
  return httpRequest({
    method: 'get',
    url: "/console/v1/logs/scan"
  })
}

export function getScanInfo(args) {
  var endpoint = "/console/v1/scan/:sid"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return axios({
    url: endpoint,
    baseURL: URL_BASE,
    headers: GetSecurityHeader()
  }).then((response) => response.data);
}

export function getLatestScan(args) {
  var endpoint = "/console/v1/workspace/:wid/latestscan"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return axios({
    url: endpoint,
    baseURL: URL_BASE,
    headers: GetSecurityHeader()
  }).then((response) => response.data);
}

export function getScanList(args) {
  var endpoint = "/console/v1/workspace/:wid/scans"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getScanAssetIPs(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/ips"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getScanAssetCIDR(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/cidr"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getScanAssetDiscover(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/discover"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getScanAssetASN(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/asn"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getSubdomains(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/subdomain"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  // return httpRequest({
  //   method: 'get',
  //   url: endpoint
  // })
  return axios({
    url: endpoint,
    baseURL: URL_BASE,
    headers: GetSecurityHeader()
  }).then((response) => response.data);
}

export function getSubdomainVulnerabilities(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/findings"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getSubdomainWeb(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/web"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getSubdomainOSINT(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/osint"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getSubdomainNMAP(args) {
  var endpoint = "/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/nmap"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return httpRequest({
    method: 'get',
    url: endpoint
  })
}

export function getFindingDetails(args) {
  var endpoint = "/console/v1/finding/:type/:id"

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  return axios({
    url: endpoint,
    baseURL: URL_BASE,
    headers: GetSecurityHeader()
  }).then((response) => response.data);

  // return httpRequest({
  //   method: 'get',
  //   url: endpoint
  // })
}
