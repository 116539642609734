import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import React, { useState } from 'react';
import { Profile, AppContext } from 'context';

import { Index } from "routes";

import {
  NotFound,
  Dashboard,
  Dashboard2,
  Findings,
  AttackSurface,
  AttackSurface2,
  Scans,
  Reports,
  Activity,
  Settings,
  Prototype,
  Signin,
  Signout,
  Signup
} from "routes/main";

import { ThemeProvider } from "styled-components";
import { Light, Dark } from "styles/Theme.styled";
import { GlobalStyles } from "styles/Global.styled";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    children: [{
      path: "/",
      element: <Dashboard />
    }, {
      path: "/dashboard",
      element: <Dashboard />
    }, {
      // todo
      path: "/dashboard2",
      element: <Dashboard2 />
    }, {
      path: "/findings",
      element: <Findings />
    }, {
      path: "/attack-surface",
      element: <AttackSurface />
    }, {
      // todo
      path: "/attack-surface2",
      element: <AttackSurface2 />
    }, {
      path: "/scans",
      element: <Scans />
    }, {
      path: "/reports",
      element: <Reports />
    }, {
      path: "/activity",
      element: <Activity />
    }, {
      path: "/settings",
      element: <Settings />
    }, {
      path: "/prototype",
      element: <Prototype />
    }, {
      path: "*",
      element: <NotFound />
    },]
  }, {
    path: "/signin",
    element: <Signin />
  }, {
    path: "/signout",
    element: <Signout />
  }, {
    path: "/signup",
    element: <Signup />
  }
]);

export default function App() {
  // eslint-disable-next-line no-unused-vars
  const [profile, setProfile] = useState(Profile);

  return (
    <AppContext.Provider value={{ profile: profile, setProfile: setProfile }}>
      <ThemeProvider theme={profile.lightTheme ? Light : Dark} >
        <GlobalStyles />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AppContext.Provider>
  )
}
