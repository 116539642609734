import { CreateSlice } from "stores/createSlice"
import { CreateSlice2 } from "stores/createSlice2"

export const createAssetSlice2 = CreateSlice2("asset2", [{
  "name": "overview",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/stat/asset"
  }
}, {
  "name": "workspaces",
  "http_req": {
    "method": "get",
    "url": "https://api.cloudmatos.ai/console/v1/workspace/names"
  }
}])

export const createAssetSlice = CreateSlice("asset", [{
  "name": "latest_scan",
  "action": "getLatestScan",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/workspace/:wid/latestscan"
},
{
  "name": "asset_ips",
  "action": "getScanAssetIPs",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/ips"
},
{
  "name": "asset_cidr",
  "action": "getScanAssetCIDR",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/cidr"
},
{
  "name": "getScanAssetDiscover",
  "action": "getScanAssetDiscover",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/discover"
},
{
  "name": "asset_asn",
  "action": "getScanAssetASN",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/asn"
},
{
  "name": "subdomains",
  "action": "getSubdomains",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/subdomain"
},
{
  "name": "findings",
  "action": "getSubdomainVulnerabilities",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/findings"
},
{
  "name": "web",
  "action": "getSubdomainWeb",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/web"
},
{
  "name": "osint",
  "action": "getSubdomainOSINT",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/osint"
},
{
  "name": "nmap",
  "action": "getSubdomainNMAP",
  "method": "get",
  "url": "https://api.cloudmatos.ai/console/v1/asset/workspace/:wid/scan/:sid/subdomain/:sdid/nmap"
}])
