import SlSkeleton from '@shoelace-style/shoelace/dist/react/skeleton';

const css = `
  .skeleton-page header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .skeleton-page header sl-skeleton:last-child {
    flex: 0 0 auto;
    width: 30%;
  }

  .skeleton-page sl-skeleton {
    margin-bottom: 1rem;
  }

  .skeleton-page sl-skeleton:nth-child(1) {
    float: left;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    vertical-align: middle;
  }

  .skeleton-page sl-skeleton:nth-child(3) {
    width: 95%;
  }

  .skeleton-page sl-skeleton:nth-child(4) {
    width: 80%;
  }
`;

const Skeleton = () => (
  <>
    <div className="skeleton-page">
      <header>
        <SlSkeleton effect="sheen" />
        <SlSkeleton effect="sheen" />
      </header>

      <SlSkeleton effect="pulse" />
      <SlSkeleton effect="pulse" />
      <SlSkeleton effect="pulse" />
    </div>

    <style>{css}</style>
  </>
);

export default Skeleton;