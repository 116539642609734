import Chart from "react-apexcharts";
import { useTheme } from 'styled-components'

export default function Treemap(props) {
  const theme = useTheme()

  const options = {
    legend: {
      show: false
    },
    chart: {
      type: 'treemap',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: [theme.color.text]
      },
      formatter: function (text, op) {
        return [text, op.value]
      },
      offsetY: -4
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.5,
        reverseNegativeShade: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 50,
              color: '#FACD41'
            },
            {
              from: 50,
              to: 60,
              color: '#F0963C'
            },
            {
              from: 60,
              to: 80,
              color: '#DC5F23'
            },
            {
              from: 80,
              to: 100,
              color: '#AA281E'
            }
          ]
        }
      }
    }
  }

  return (
    <Chart options={options} series={props.series} type="treemap" height={250} width={480} />
  )
}