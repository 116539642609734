import { styled, css } from "styled-components"
import { AppContext } from 'context';
import { useContext, useEffect } from 'react';

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.$rows && "row" ||
    props.$irows && "row-reverse" ||
    props.$cols && "column" ||
    props.$icols && "column-reverse"};

  gap: ${props => props.$g5 && "5px" ||
    props.$g10 && "10px" ||
    props.$g15 && "15px" ||
    props.$g20 && "20px" ||
    "0px"};

  flex-wrap: ${props => props.$wr && "wrap" ||
    props.$iwr && "wrap-reverse" ||
    "nowrap"};

  justify-content: ${props => props.$jcfs && "flex-start" ||
    props.$jcfe && "flex-end" ||
    props.$jcc && "center" ||
    props.$jcsb && "space-between" ||
    props.$jcsa && "space-around" ||
    props.$jcse && "space-evenly" ||
    "flex-start"};
  
  align-items: ${props => props.$ais && "stretch" ||
    props.$aifs && "flex-start" ||
    props.$aife && "flex-end" ||
    props.$aic && "center" ||
    props.$aib && "baseline" ||
    "stretch"};

  padding: ${props => props.$pd5 && "5px 5px" ||
    props.$pd10 && "10px 10px" ||
    props.$pd15 && "15px 15px" ||
    props.$pd20 && "20px 20px" ||
    "0px 0px"};

  ${props => props.$bd && css`
    border: 1px solid ${({ theme }) => theme.color.border};
    border-radius: 6px;
  `}
`

export const FlexItem = styled.div`
  ${props => props.$bd && css`
    border: 1px solid ${({ theme }) => theme.color.border};
    border-radius: 6px;
  `}

  flex: ${props => props.$fx || "none"};

  padding: ${props => props.$pd5 && "5px 5px" ||
    props.$pd10 && "10px 10px" ||
    props.$pd15 && "15px 15px" ||
    props.$pd20 && "20px 20px" ||
    "0px 0px"};

  align-self: ${props => props.$asa && "auto" ||
    props.$asfs && "flex-start" ||
    props.$asfe && "flex-end" ||
    props.$asc && "center" ||
    props.$asb && "baseline" ||
    props.$ass && "stretch" ||
    "auto"};

  text-align: ${props => props.$tal && "left" ||
    props.$tar && "right" ||
    props.$tac && "center" ||
    "left"};

  margin: ${props => props.$m5 && "5px 0px" ||
    props.$m10 && "10px 0px" ||
    props.$m15 && "15px 0px" ||
    props.$m20 && "20px 0px" ||
    "0px 0px"};
`

export const FlexCols = styled(Flex).attrs({
  $cols: true
})`
`

export const FlexRows = styled(Flex).attrs({
  $rows: true,
})`
`

export function Page(props) {
  const context = useContext(AppContext);

  useEffect(() => {
    var profile = {
      ...context.profile,
      sidebarSelection: props.route
    }

    context.setProfile(profile);
  }, [props.route])

  return <FlexCols>{props.children}</FlexCols>
}

export const PageHeader = styled(FlexRows).attrs({
  $jcsb: true,
})`
  margin-top: 0px;
  width: 87%;
  height: var(--mx-navmenu-height);
  align-items: center;
  font-size: var(--sl-font-size-small);
  font-weight: var(--sl-font-weight-bold);
  @media (min-width: 1280px) {
    margin-top: calc(0px - var(--mx-navmenu-height) - 3px);
  }
`

export const Title = styled(FlexItem).attrs({
  $jcsb: true,
})`
`

export const Toolbar = styled(FlexItem).attrs({
  $jcsb: true,
})`
  margin-right: var(--sl-spacing-x-small);
`

export const ToolbarItem = styled.span`
  margin-right: var(--sl-spacing-small);
`

export const PageBody = styled.div`
  margin: ${props => props.$m5 && "5px 0px" ||
    props.$m10 && "10px 0px" ||
    props.$m15 && "15px 0px" ||
    props.$m20 && "20px 0px" ||
    "0px 0px"};
`