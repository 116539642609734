import styled from 'styled-components';

const StyledFooter = styled.footer`
  grid-area: footer;
  padding: 0.25rem;
  text-align: center;
`;

export function Footer({ children }) {
  return <StyledFooter>{children}</StyledFooter>
}
