import { Toolbar, FlexCols, FlexRows, FlexItem } from "lib/core/flex"
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { SplitProgress } from "lib/charts/splitProgress";
import Chart from "react-apexcharts";

import useSessionData from "stores/useSessionData";
import { DataSafeComponent, NoData } from "routes/main";

const Panel = styled.div`
  height: ${props => props.$ht || "74vh"};
  overflow-y: auto;
  margin: 0px -10px;
  padding: 20px;
`

const ListItem = styled(FlexRows)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`

const RowItem = styled(FlexCols)`
  cursor: pointer;
  width: 32%;
  min-width: 300px;
  height: 200px;
  border-radius: 6px;
  padding: 15px;
  gap: 16%;
  transition: transform 0.3s ease 0s;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`

// const Toolbar = styled.div`
//   margin-right: var(--sl-spacing-x-small);
// `

// const ListItem = styled(FlexItem).attrs((props) => ({
//   $jcc: props.$jcc || true
// }))`
//   padding: 15px 10px;
//   height: 50px;
//   border-radius: 0px;
//   border-bottom: 1px solid ${({ theme }) => theme.color.border};
//   &:hover {
//     background-color: ${({ theme }) => theme.color.hoverBackground};
//   }
// `


const options = {
  chart: {
    type: 'bar',
    width: 100,
    height: 35,
    sparkline: {
      enabled: true
    }
  },
  stroke: {
    curve: 'straight',
    width: 2
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        // eslint-disable-next-line no-unused-vars
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
}


function WorkspaceList(props) {
  const navigate = useNavigate();

  function onClickHandler() {
    navigate("/findings");
  }

  return (
    <>
      <FlexCols $g15>
        <Panel>
          {
            <FlexCols $g10>
              {props?.data?.items?.map((item, i) => (
                <ListItem $bd $pd15 $g20 key={i} onClick={() => onClickHandler()}>
                  {
                    (() => {
                      const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low + item?.severity_score?.info
                      const critical = parseInt((item?.severity_score?.critical * 100) / findings)
                      const high = parseInt((item?.severity_score?.high * 100) / findings)
                      const medium = parseInt((item?.severity_score?.medium * 100) / findings)
                      const low = parseInt((item?.severity_score?.low * 100) / findings)
                      // const info = parseInt((item?.severity_score?.info * 100) / findings)
                      const info = 100 - critical - high - medium - low

                      return (
                        <>
                          <FlexItem $fx="1.5">
                            <FlexCols>
                              <FlexItem><span style={{ fontSize: "0.9rem" }}>{item?.name}</span></FlexItem>
                              <FlexItem>
                                <small style={{ color: "#909090" }}>Findings: {findings} <br />Domains: {item?.assets?.domains} | Subdomains: {item?.assets?.sub_domains} | IPs: {item?.assets?.ips} | Ports: {item?.assets?.ports} </small>
                              </FlexItem>
                            </FlexCols>
                          </FlexItem>
                          <FlexItem $fx="1"><SplitProgress progressValues={[critical, high, medium, low, info]} /></FlexItem>
                          <FlexItem $fx="0.2">
                            <Chart options={options} series={[{ data: item?.trend }]} type="bar" height={35}
                              width={100} />
                          </FlexItem>
                        </>
                      )
                    })()
                  }
                </ListItem>
              ))}
            </FlexCols>
          }
        </Panel>
      </FlexCols>
    </>
  )
}

function WorkspaceCards(props) {
  const navigate = useNavigate();

  function onClickHandler() {
    navigate("/findings");
  }

  return (
    <>
      <FlexCols $g15>
        <Panel>
          {
            <FlexRows $g15 $jcsb $wr>
              {props?.data?.items?.map((item, i) => (
                <RowItem $bd key={i} onClick={() => onClickHandler()}>
                  {
                    (() => {
                      const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low + item?.severity_score?.info
                      const critical = parseInt((item?.severity_score?.critical * 100) / findings)
                      const high = parseInt((item?.severity_score?.high * 100) / findings)
                      const medium = parseInt((item?.severity_score?.medium * 100) / findings)
                      const low = parseInt((item?.severity_score?.low * 100) / findings)
                      // const info = parseInt((item?.severity_score?.info * 100) / findings)
                      const info = 100 - critical - high - medium - low

                      return (
                        <>
                          <FlexItem>
                            <FlexCols>
                              <FlexItem><span style={{ fontSize: "0.9rem" }}>{item?.name}</span></FlexItem>
                              <FlexItem>
                                <small style={{ color: "#909090" }}>Findings: {findings} <br />Domains: {item?.assets?.domains} | Subdomains: {item?.assets?.sub_domains} | IPs: {item?.assets?.ips} | Ports: {item?.assets?.ports} </small>
                              </FlexItem>
                            </FlexCols>
                          </FlexItem>
                          <FlexItem><SplitProgress progressValues={[critical, high, medium, low, info]} /></FlexItem>
                          <FlexItem $asfe>
                            <Chart options={options} series={[{ data: item?.trend }]} type="bar" height={35}
                              width={200} />
                          </FlexItem>
                        </>
                      )
                    })()
                  }
                </RowItem>
              ))}
            </FlexRows>
          }
        </Panel>
      </FlexCols>
    </>
  )
}

function Workspaces() {
  const [view, setView] = useState("list");
  // let { workspaceId } = useParams(); 

  var args = {
    slice_name: "finding",
    store_name: "workspaces"
  }

  var resource = useSessionData(args)

  function handleViewChange(viewType) {
    setView(viewType)
  }

  function isListView() {
    return view === "list"
  }

  return (
    <>
      {
        resource && (
          <>
            <FlexCols $aife>
              <Toolbar>
                <SlButtonGroup label="Alignment">
                  <SlTooltip content="List view">
                    <SlButton onClick={() => handleViewChange("list")}>
                      <SlIcon name="list-ul"></SlIcon>
                    </SlButton>
                  </SlTooltip>
                  <SlTooltip content="Card view">
                    <SlButton onClick={() => handleViewChange("card")}>
                      <SlIcon name="credit-card-2-front"></SlIcon>
                    </SlButton>
                  </SlTooltip>
                </SlButtonGroup>
              </Toolbar >
            </FlexCols>
            {isListView() ? < WorkspaceList data={resource?.data} /> : <WorkspaceCards data={resource?.data} />}
          </>
        )
      }
      {
        <NoData criteria={resource === undefined} />
      }
    </>
  )
}

export const WorkspaceTab = () => {
  return (
    <DataSafeComponent>
      <Workspaces />
    </DataSafeComponent>
  )
}