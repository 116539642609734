import React from "react";

import Chart from "react-apexcharts";
import { useTheme } from 'styled-components'

export const GradientProgressChart = (props) => {
  const theme = useTheme()

  const options = {
    chart: {
      type: 'radialBar',
      offsetY: -15,
      // foreColor: ({ theme }) => theme.color.text,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        // startAngle: 0,
        // endAngle: 360,
        hollow: {
          margin: 0,
          size: '70%',
          // eslint-disable-next-line no-constant-condition
          background: theme.color.background,
          // image: undefined,
          // imageOffsetX: 0,
          // imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: -3,
            left: -3,
            blur: 10,
            opacity: 0.01
          }
        },
        track: {
          background: '#fafafa',
          strokeWidth: '100%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: theme.color.text,
            fontSize: '0.8rem',
            fontWeight: 500
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            offsetY: 5,
            color: theme.color.text,
            fontSize: '0.9rem',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Risk Score'],
  };

  const series = [props.value];

  return <Chart width="200" options={options} series={series} type="radialBar" height="200" />;
};
