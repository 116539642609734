import { ToolbarItem } from 'lib/core/flex';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import { useState, useEffect } from "react";

export const ToolbarItems = (props) => {

  const [selected, setSelected] = useState(1)

  useEffect(() => {
    props.setView(selected)
  }, [selected])

  function isSelected(id) {
    return (selected == id) ? "primary" : "default"
  }

  return (
    <ToolbarItem>
      <SlButtonGroup label="Views">
        <SlTooltip content="Split view">
          <SlButton variant={isSelected(1)} size="medium" onClick={() => setSelected(1)}>
            <SlIcon name="window-sidebar"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="List view">
          <SlButton variant={isSelected(2)} size="medium" onClick={() => setSelected(2)}>
            <SlIcon name="list-ul"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="Card view">
          <SlButton variant={isSelected(3)} size="medium" onClick={() => setSelected(3)}>
            <SlIcon name="credit-card-2-front"></SlIcon>
          </SlButton>
        </SlTooltip>
      </SlButtonGroup>
    </ToolbarItem>
  )
}
