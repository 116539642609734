import { Page, PageHeader, PageBody, Title, Toolbar, ToolbarItem } from "lib/core/flex"
import { DataSafePage } from 'routes/main'
import { Details } from "routes/partials/findings";
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import { useEffect, useState } from "react";

function ToolbarItems(props) {
  const [selected, setSelected] = useState(1)

  useEffect(() => {
    props.setView(selected)
  }, [selected])

  function isSelected(id) {
    return (selected == id) ? "primary" : "default"
  }

  return (
    <ToolbarItem>
      <SlButtonGroup label="Views">
        <SlTooltip content="Split view">
          <SlButton variant={isSelected(1)} size="medium" onClick={() => setSelected(1)}>
            <SlIcon name="window-sidebar"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="List view">
          <SlButton variant={isSelected(2)} size="medium" onClick={() => setSelected(2)}>
            <SlIcon name="list-ul"></SlIcon>
          </SlButton>
        </SlTooltip>
        <SlTooltip content="Card view">
          <SlButton variant={isSelected(3)} size="medium" onClick={() => setSelected(3)}>
            <SlIcon name="credit-card-2-front"></SlIcon>
          </SlButton>
        </SlTooltip>
      </SlButtonGroup>
    </ToolbarItem>
  )
}

export default function Findings() {
  const [view, setView] = useState(1)

  return (
    <Page route="findings">
      <PageHeader>
        <Title>Findings</Title>
        <Toolbar><ToolbarItems setView={setView} /></Toolbar>
      </PageHeader>
      <PageBody $m15>
        <DataSafePage>
          <Details view={view} />
        </DataSafePage>
      </PageBody>
    </Page>
  )
}