export const FindingsCount = (props) => {
  return (
    props.severity && (
      <span>
        {
          props.severity?.critical + props.severity?.high +
          props.severity?.medium + props.severity?.low
        }
      </span>
    )
  )
}