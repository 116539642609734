import { useEffect, useState, useRef } from 'react';
import { RemoveToken } from 'lib/core/localstore';
import { GUI_SIGNIN } from "lib/core/gui_endpoints"
import { useNavigate } from "react-router-dom";
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import { logoutUser } from 'lib/core/bera';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import styled from "styled-components";

const StyledToastContainer = styled.div`
`

export default function Signout() {
  const navigate = useNavigate();

  const success = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [okmsg, setOkMsg] = useState("You have successfully signed out!")

  useEffect(() => {
    (async () => {
      await logoutUser();
    })();

    RemoveToken();
    success.current.toast()
    navigate(GUI_SIGNIN);
  }, []);

  return (
    <>
      <StyledToastContainer>
        <SlAlert ref={success} variant="success" duration="3000" closable>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Success</strong>
          <br />
          {okmsg}
        </SlAlert>
      </StyledToastContainer>
      <p>signout</p>
      <SlSpinner style={{ fontSize: '3rem' }} />
    </>
  )
}