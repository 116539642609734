import { useState, useEffect } from "react";
import axios from "axios";
import GetSecurityHeader from 'lib/core/security_header';
import { useAppStore } from 'stores/appStore';

const promiseWrapper = (promise) => {
  let status = "pending";
  let result;

  const s = promise.then(
    (value) => {
      status = "success";
      result = value;
    },
    (error) => {
      status = "error";
      result = error;
    }
  );

  return () => {
    switch (status) {
    case "pending":
      throw s;
    case "success":
      return result;
    case "error":
      throw result;
    default:
      throw new Error("Unknown status");
    }
  };
};

var activeEnpoints = []

function useSessionData(args) {
  const store = useAppStore((state) => state[args.slice_name])
  const getHttpReq = useAppStore((state) => state["http_req_" + args.slice_name + "_" + args.store_name])
  const updateStore = useAppStore((state) => state["update_" + args.slice_name + "_" + args.store_name])

  var http_req = getHttpReq()
  var endpoint = http_req["url"];

  const [resource, setResource] = useState(null);

  if (args?.path) {
    for (const key in args?.path) {
      endpoint = endpoint.replaceAll(":" + key, encodeURIComponent(args.path[key]))
    }
  }

  useEffect(() => {
    const getData = async () => {
      const promise = axios({
        method: http_req["method"],
        url: endpoint,
        headers: GetSecurityHeader(),
        data: http_req["data"],
        params: http_req["params"],
      }).then((response) => {
        updateStore(response.data)
        return response.data
      });
      setResource(promiseWrapper(promise));
    };

    if (!activeEnpoints.includes(http_req["url"])) {
      activeEnpoints.push(http_req["url"])
      getData();
    } else {
      if (store[args.store_name].d == null || store[args.store_name].d == undefined) {
        setResource(undefined)
      } else {
        setResource(store[args.store_name].d)
      }
    }

  }, [args]);

  return resource;
}

export default useSessionData;