import { styled, css } from 'styled-components';
import React, { useState, useContext, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import { AppContext } from 'context';
import { GUI_SIGNIN } from "lib/core/gui_endpoints"
import { ValidateToken, RemoveToken } from 'lib/core/localstore';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
`;

const StyledMenuItem = styled.a`
  padding: var(--mx-navmenu-item-padding);
  border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.background};;
  text-decoration: none;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s linear;
  align-items: flex-start;

  ${props => props.$selected && css`
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
  `}

  &:hover {
    background-color: ${({ theme }) => theme.color.hoverBackground};
  }

  @media (max-width: 550px) {
    width: 49.8vw;
  }
`;

const StyledMenuIcon = styled.span`
  font-size: 18px;
`

const StyledMenuLabel = styled.span`
  padding: 0 22px;
  white-space: nowrap;
`
const StyledToastContainer = styled.div`
`

const items = [
  {
    name: "Dashboard",
    route: "dashboard",
    icon: "speedometer2"
  },
  {
    name: "Findings",
    route: "findings",
    icon: "shield-exclamation",
  },
  {
    name: "Attack Surface",
    route: "attack-surface",
    icon: "layers"
  },
  {
    name: "Scans",
    route: "scans",
    icon: "clock-history"
  },
  {
    name: "Reports",
    route: "reports",
    icon: "journal-check"
  },
  {
    name: "Activity",
    route: "activity",
    icon: "list-task"
  },
  {
    name: "Settings",
    route: "settings",
    icon: "gear"
  }
];

export function SidebarMenuItems() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(context.profile.sidebarSelection);

  const success = useRef(null);
  const error = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [okmsg, setOkMsg] = useState("")
  const [errmsg, setErrMsg] = useState("")

  useEffect(() => {
    var profile = {
      ...context.profile,
      sidebarSelection: selected
    }

    context.setProfile(profile);

    (async () => {
      const result = await ValidateToken(false);

      if (!result) {
        RemoveToken();
        setErrMsg("Session expired!")
      }
    })();
  }, [selected]);

  useEffect(() => {
    if (errmsg.length) {
      error.current.toast()
      navigate(GUI_SIGNIN);
    }
  }, [errmsg])

  function isSelected(value) {
    return context.profile.sidebarSelection === value;
  }

  function clickHandler(e, target) {
    e.preventDefault();
    setSelected(target);
    navigate("/" + target)
  }

  return (
    <>
      <StyledToastContainer>
        <SlAlert ref={success} variant="success" duration="3000" closable>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Success</strong>
          <br />
          {okmsg}
        </SlAlert>
        <SlAlert ref={error} variant="danger" duration="3000" closable>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>Error</strong>
          <br />
          {errmsg}
        </SlAlert>
      </StyledToastContainer>
      <StyledMenuContainer>
        {context.profile.sidebarOpen && items.map((item, i) => (
          <StyledMenuItem key={i} $selected={isSelected(item.route)} onClick={(e) => clickHandler(e, item.route)}>
            <StyledMenuIcon><SlIcon name={item.icon} /></StyledMenuIcon>
            <StyledMenuLabel>{item.name}</StyledMenuLabel>
          </StyledMenuItem>
        ))}
        {!context.profile.sidebarOpen && items.map((item, i) => (
          <SlTooltip key={i} content={item.name} placement="right">
            <StyledMenuItem key={i} $selected={isSelected(item.route)} onClick={(e) => clickHandler(e, item.route)}>
              <StyledMenuIcon><SlIcon name={item.icon} /></StyledMenuIcon>
              <StyledMenuLabel>{item.name}</StyledMenuLabel>
            </StyledMenuItem>
          </SlTooltip>
        ))}
      </StyledMenuContainer>
    </>

  )
}
