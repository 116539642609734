import styled from 'styled-components';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';
import SlSwitch from '@shoelace-style/shoelace/dist/react/switch';


const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`

const StyledField = styled.div`
  display: flex;
  width: 300px;
`


export const SchedulerSettings = () => {
  return (
    <StyledTabContainer>
      <StyledRow>
        <StyledField>Scheduler</StyledField>
        <SlSwitch checked />
      </StyledRow>
      <StyledRow>
        <StyledField>Schedule your scans</StyledField>
        <SlDropdown>
          <SlButton slot="trigger" caret>
            Every month
          </SlButton>
          <SlMenu>
            <SlMenuItem value="cut">Every week</SlMenuItem>
            <SlMenuItem value="copy">Every month</SlMenuItem>
            <SlMenuItem value="paste">Every 3 months</SlMenuItem>
            <SlMenuItem value="paste">Every 6 months</SlMenuItem>
          </SlMenu>
        </SlDropdown>
      </StyledRow>
    </StyledTabContainer>
  )
}