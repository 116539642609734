import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetToken, SetToken, SetUname, GetUname, RemoveUname, SetUser } from "lib/core/localstore";
import { GUI_HOME } from "lib/core/gui_endpoints"
import styled from "styled-components";
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import { FlexCols, FlexItem, FlexRows } from 'lib/core/flex';
import { loginUser } from 'lib/core/bera';

const StyledLabel = styled.div`
  font-size: var(--sl-font-size-small);
  padding-bottom: 5px;
`

const StyledError = styled.p`
  color: var(--sl-color-danger-500)
`

const PanelOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100wh;
  height: 100vh;
  padding: 50px;
  min-height: 500px;
`

const PanelSignin = styled(FlexCols)`
  min-width: 320px;
  width: calc(100vw - 80px);
  max-width: 600px;
  gap: 20px;
  padding: 50px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
  
  // @media (min-device-width: 360px) and (max-device-width: 1024px) and (orientation:portrait) {
  // }

  // @media (min-device-width: 360px) and (max-device-width: 1024px) and (orientation:landscape) {
  // }
`

const StyledToastContainer = styled.div`
`

export default function Signin() {
  const uname = GetUname()
  const [remmember, setRemember] = useState(uname !== null)
  const [username, setUserName] = useState(remmember ? (uname ? uname : "") : "");
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(GetToken() !== null)
  const [authError, setAuthError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disableSignin, setDisableSignin] = useState(true)
  const [lockSignin, setlockSignin] = useState(false)
  const navigate = useNavigate();
  const errMsg = "Authentication failed. Please check your username/password"

  const success = useRef(null);
  const error = useRef(null);
  const [okmsg, setOkMsg] = useState("")
  const [errmsg, setErrMsg] = useState("")

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true)
    setAuthError(false)
  
    setlockSignin(true)
    setInterval(() => {
      setlockSignin(false)
    }, 5000);

    if (remmember) {
      if (username.trim() !== "")
        SetUname(username)
    } else {
      RemoveUname()
    }

    const response = await loginUser({
      "identity": username,
      "security": password
    });

    if (response?.data) {
      var hasToken = response.data.token !== undefined

      if (hasToken) {
        setOkMsg("You have successfully signed in!")
        success.current.toast()
        SetToken(response.data.token)
        SetUser({i: response.data.name, r: response.data.role})
      }

      setAuthenticated(hasToken)
      setAuthError(!hasToken)
    } else {
      setErrMsg(response?.message)
      error.current.toast()
    }

    setLoading(false)
  }

  useEffect(() => {
    if (authenticated) {
      navigate(GUI_HOME)
    }
  }, [authenticated]);

  useEffect(() => {
    setDisableSignin(username.trim() === "" || password.trim() === "")
  }, [username, password]);

  return (
    <PanelOuter>
      <StyledToastContainer>
        <SlAlert ref={success} variant="success" duration="3000" closable>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Success</strong>
          <br />
          {okmsg}
        </SlAlert>
        <SlAlert ref={error} variant="danger" duration="3000" closable>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>Error</strong>
          <br />
          {errmsg}
        </SlAlert>
      </StyledToastContainer>
      <form onSubmit={handleSubmit}>
        <PanelSignin $bd>
          <FlexItem>
            <h2>Sign in to Metos</h2>
          </FlexItem>
          <FlexItem>
            {authError && <StyledError>{errMsg}</StyledError>}
            {!authError && <StyledError>&nbsp;</StyledError>}
          </FlexItem>
          <FlexItem>
            <SlInput label='Username' name="username" placeholder="Username" size="medium"
              onSlInput={e => setUserName(e.target.value)} value={username}>
            </SlInput>
          </FlexItem>
          <FlexItem>
            <SlInput label='Password' name="password" type="password" placeholder="Password" size="medium" password-toggle
              onSlInput={e => { setPassword(e.target.value) }}>
            </SlInput>
          </FlexItem>
          <FlexRows $pd5 $jcsb $aic $wr>
            <FlexItem>
              <SlCheckbox onSlChange={e => setRemember(e.target.checked)} checked={remmember}>
                <StyledLabel>
                  Remember me
                </StyledLabel>
              </SlCheckbox>
            </FlexItem>
            <FlexItem>
              <SlButton href="https://app.cloudmatos.ai" variant="text">
                Forgot Password
              </SlButton>
            </FlexItem>
          </FlexRows>
          <FlexItem>
            <SlButton type="submit" variant="primary" style={{ width: '100%' }} loading={loading} disabled={lockSignin || disableSignin}>Sign in</SlButton>
          </FlexItem>
        </PanelSignin>
        <FlexCols>
          <FlexItem $tac $pd20><div>© 2024 CloudMatos.ai</div></FlexItem>
        </FlexCols>
      </form>
    </PanelOuter>
  )
}