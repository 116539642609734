import SlInput from '@shoelace-style/shoelace/dist/react/input';
import styled from 'styled-components';

import { DataSafeComponent } from 'routes/main'
import useSessionData from "stores/useSessionData";

const StyledLabel = styled.div`
  font-size: var(--sl-font-size-small);
  padding-bottom: 5px;
`
const StyledTable = styled.table`
  width: 100%;
`

const StyledTBody = styled.tbody`
`

const StyledTRow = styled.tr`
`

const StyledTdata = styled.td`
  padding: 10px;
`

const StyledVATdata = styled(StyledTdata)`
  vertical-align: top;
  width: 30%;
`

const OrganizationInfo = () => {
  var args = {
    slice_name: "organization",
    store_name: "basic_info"
  }

  var resource = useSessionData(args)

  return (
    resource && (
      <>
        <StyledTable>
          <StyledTBody>
            <StyledTRow>
              <StyledVATdata>
                <h3>Organization Info</h3>
                <br />
                {resource?.data?.org_id}
              </StyledVATdata>
              <StyledTdata>
                <StyledTable>
                  <StyledTBody>
                    <StyledTRow>
                      <StyledTdata colSpan={2}>
                        <SlInput readonly filled name="cname" value={String(resource?.data?.org_name)}><StyledLabel slot='label'>Organization Name</StyledLabel></SlInput>
                      </StyledTdata>
                    </StyledTRow>
                    <StyledTRow>
                      <StyledTdata>
                        <SlInput readonly filled name="fname" value={String(resource?.data?.first_name)}><StyledLabel slot='label'>First Name</StyledLabel></SlInput>
                      </StyledTdata>
                      <StyledTdata>
                        <SlInput readonly filled name="lname" value={String(resource?.data?.last_name)}><StyledLabel slot='label'>Last Name</StyledLabel></SlInput>
                      </StyledTdata>
                    </StyledTRow>
                    <StyledTRow>
                      <StyledTdata>
                        <SlInput readonly filled name="email" value={String(resource?.data?.email)}><StyledLabel slot='label'>Email</StyledLabel></SlInput>
                      </StyledTdata>
                      <StyledTdata>
                        <SlInput readonly filled name="phone" value={String(resource?.data?.phone)}><StyledLabel slot='label'>Phone</StyledLabel></SlInput>
                      </StyledTdata>
                    </StyledTRow>
                  </StyledTBody>
                </StyledTable>
              </StyledTdata>
            </StyledTRow>
          </StyledTBody>
        </StyledTable>
      </>
    )
  )
}

export const GeneralSettings = () => {
  return (
    <>
      <DataSafeComponent>
        <OrganizationInfo />
      </DataSafeComponent>
    </>
  )
}