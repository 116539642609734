import useSessionData from "stores/useSessionData";
import { FlexCols, FlexItem } from "lib/core/flex";
import styled from "styled-components";

const StyledH1 = styled.h1`
  font-size: var(--sl-font-size-2x-large);
`

export const SummaryScans = () => {
  var args = {
    slice_name: "scan",
    store_name: "summary"
  }

  var resource = useSessionData(args)

  return (
    <FlexCols $g10>
      <FlexItem></FlexItem>
      {
        resource &&
        (
          <>
            <FlexItem $asc>Completed</FlexItem>
            <FlexItem $asc><StyledH1>{resource?.data?.completed}</StyledH1></FlexItem>
            <FlexItem></FlexItem>
            <FlexItem $asc>Inprogress</FlexItem>
            <FlexItem $asc><StyledH1>{resource?.data?.inprogress}</StyledH1></FlexItem>
          </>
        )
      }
    </FlexCols>
  )
}