import { Suspense } from 'react';
import ErrorBoundary from "lib/core/error_boundary";
import Loading from "lib/sx/loading";
import NetworkError from "lib/sx/network_error";

export default function DataSafeComponent({ children }) {
  return (
    <ErrorBoundary fallback={<NetworkError />}>
      <Suspense fallback={<Loading />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}