
import useSessionData from "stores/useSessionData";
import { DonutChart } from "lib/charts/donutChart";
import NoData from "lib/sx/no_data";
import { FlexCols } from "lib/core/flex";

export const Category = () => {
  var args = {
    slice_name: "stat",
    store_name: "category"
  }

  var resource = useSessionData(args)

  return (
    resource && (
      resource.data?.series && resource.data.labels ? (
        <FlexCols $g10 style={{ fontSize: "0.75rem" }}>
          <DonutChart series={resource.data?.series} labels={resource.data.labels} />
        </FlexCols>
      ) : <NoData />
    )
  )
}
