import { useEffect, useState } from 'react';
import { styled, css } from 'styled-components';
import Chart from 'react-apexcharts';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
// import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { FlexCols, FlexItem, FlexRows } from 'lib/core/flex';
import { SplitProgress } from 'lib/charts/splitProgress';

import useSessionData from 'stores/useSessionData';
import { DataSafeComponent, NoData } from 'routes/main';
import {
  getLatestScan,

} from "lib/core/bera";

import {
  TopPanel,
  WorkspaceContent
} from 'routes/partials/attackSurface';

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex-flow: row wrap;
  flex: 1;
  overflow-y: scroll;
  border-right: 1px solid ${({ theme }) => theme.color.border};
`;

const StyledMenuItem = styled.a`
  padding: var(--mx-navmenu-item-padding);
  border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.background};;
  text-decoration: none;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s linear;
  height: 86px;

  border-bottom: 1px dotted ${({ theme }) => theme.color.border};

  &:hover {
    background-color: ${({ theme }) => theme.color.hoverBackground};
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.hoverBackground};
  }

  ${props => props.$current && css`
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
    &:hover {
      border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
    }
  `}

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
`;

const StyledRows = styled(FlexRows)`
  border-radius: 0px;
  height: 70vh;
`

const Panel = styled.div`
  margin: -10px -10px;
  padding: 10px;
  // border: 1px solid black;
`

const ListItem = styled(FlexRows)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;
  min-height: 60px;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`

const RowItem = styled(FlexCols)`
  cursor: pointer;
  width: 32%;
  min-width: 300px;
  height: 120px;
  border-radius: 6px;
  padding: 15px;
  gap: 16%;
  transition: transform 0.3s ease 0s;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`

const StyledTableFooter = styled.div`
  // border: 1px solid red;
  // padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledBadge = styled.span`
    padding: 2px 6px;
    // border: 1px solid #909090;
    background-color: ${({ theme }) => theme.color.textFill};
    border-radius: 4px;
`

export const Content = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selected, setSelected] = useState(null)
  const [pagelevel, setPagelevel] = useState(0)
  const [latestScan, setLatestScan] = useState(null)

  var args = {
    slice_name: "finding",
    store_name: "workspaces"
  }

  var resource = useSessionData(args)

  var severityStatArgs = {
    slice_name: "stat",
    store_name: "severity"
  }

  var severityData = useSessionData(severityStatArgs)

  var assetOverviewArgs = {
    slice_name: "asset2",
    store_name: "overview"
  }

  var assetOverviewData = useSessionData(assetOverviewArgs)

  useEffect(() => {
    if (resource?.data?.items?.length > 0) {
      setSelectedIndex(0)
      setSelected(resource?.data?.items[0].id)
    }
  }, [resource])

  useEffect(() => {
    setPagelevel(0)
  }, [props.view])

  useEffect(() => {
    const wid = resource?.data?.items[selectedIndex].id
    if (wid !== undefined) {
      const args = { "path": { "wid": wid } }

      getLatestScan(args).then((res) => {
        setLatestScan(res?.data?.latest_scan)
      })
    }
  }, [selectedIndex, selected])

  function ItemBlock(props) {
    return (
      <FlexItem $fx="1.5">
        <FlexCols>
          <FlexItem>
            <span style={{ fontSize: "0.9rem" }}>{props?.item?.name}</span>
          </FlexItem>
          <FlexItem>
            <small style={{ color: "#909090" }}>
              Risk score <StyledBadge>{props?.item?.severity_score?.score}</StyledBadge>&nbsp;
              Findings <StyledBadge>{getSeverityTotal(props?.item)}</StyledBadge>
            </small>
          </FlexItem>
        </FlexCols>
      </FlexItem>
    )
  }

  function getSeverityTotal(item) {
    return item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low
  }

  function getSeverity(item) {
    const findings = getSeverityTotal(item)

    if (findings < 1) {
      return [0, 0, 0, 0]
    }

    const critical = parseInt((item?.severity_score?.critical * 100) / findings)
    const high = parseInt((item?.severity_score?.high * 100) / findings)
    const medium = parseInt((item?.severity_score?.medium * 100) / findings)
    // const low = parseInt((item?.severity_score?.low * 100) / findings)
    // const info = parseInt((item?.severity_score?.info * 100) / findings)
    const low = 100 - critical - high - medium

    return [critical, high, medium, low]
  }

  function ChartItem(props) {
    const chart_options = {
      chart: {
        type: 'bar',
        width: 100,
        height: 30,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            // eslint-disable-next-line no-unused-vars
            formatter: function (seriesName) {
              return ''
            }
          }
        },
        marker: {
          show: false
        }
      }
    }

    const trend = props?.item?.trend
    // const trend = [30, 10, 50, 20, 70, 60, 80] 

    return (
      <Chart options={chart_options} series={[{ data: trend }]} type="bar" height={30} width={100} />
    )
  }

  function PageControl(props) {
    const defPage = 1, defStart = 0, defIpp = props.defipp
    const [page, setPage] = useState(defPage)
    const [ipp, setipp] = useState(defIpp)
    // eslint-disable-next-line no-unused-vars
    const [rowCount, setRowCount] = useState(props.size)
    const [maxPage, setMaxpages] = useState(Math.ceil(rowCount / ipp))
    const [start, setStart] = props.state.s
    const [end, setEnd] = props.state.e

    useEffect(() => {
      setStart(defStart)
      setEnd(rowCount > defIpp ? defIpp : rowCount)
    }, [props.size])

    function handleSelect(event) {
      const item = event.detail.item;
      const selectedVal = Number(item.value)
      setPage(defPage)
      setipp(selectedVal)
      setStart(defStart)
      setEnd(rowCount > selectedVal ? selectedVal : rowCount)
      setMaxpages(Math.ceil(rowCount / selectedVal))
    }

    function handlePrev() {
      if (page > 1) {
        setPage(page - 1)
        setStart(start - ipp)
        setEnd(end - ipp)
      }
    }

    function handleNext() {
      if (page < maxPage) {
        setPage(page + 1)
        setStart(start + ipp)
        setEnd(end + ipp)
      }
    }

    return (
      <>
        <StyledTableFooter>
          <div>
            <SlDropdown>
              <SlButton slot="trigger" caret>
                {ipp}
              </SlButton>
              <SlMenu onSlSelect={handleSelect}>
                {
                  (props.ipp ? props.ipp : [5, 10, 20]).map((item, i) => {
                    return <SlMenuItem key={i} value={item}>{item}</SlMenuItem>
                  })
                }
              </SlMenu>
            </SlDropdown><span>&nbsp;&nbsp;&nbsp;Items per page</span>
          </div>
          <div>
            <span>{page} of {maxPage} &nbsp;&nbsp;&nbsp;</span>
            <SlButtonGroup label="Pagination">
              <SlTooltip content="Prev">
                <SlButton size="medium" onClick={handlePrev}>
                  <SlIcon name="arrow-left"></SlIcon>
                </SlButton>
              </SlTooltip>
              <SlTooltip content="Next">
                <SlButton size="medium" onClick={handleNext}>
                  <SlIcon name="arrow-right"></SlIcon>
                </SlButton>
              </SlTooltip>
            </SlButtonGroup>
          </div>
        </StyledTableFooter>
      </>
    )
  }

  function ListView() {
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)
    const state = {
      s: [start, setStart],
      e: [end, setEnd]
    }

    return (
      <>
        <FlexCols $g15>
          <Panel>
            {
              <FlexCols $g10>
                {resource?.data?.items?.slice(start, end)?.map((item, i) => (
                  <ListItem $bd $pd15 $g20 key={item.id} onClick={(e) => { handleItemSelect(e, start + i, item.id) }}>
                    <FlexItem $fx="1.5"><ItemBlock item={item} /></FlexItem>
                    <FlexItem $fx="1"><SplitProgress progressValues={getSeverity(item)} /></FlexItem>
                    <FlexItem $asfe fx="0.2"><ChartItem item={item} /></FlexItem>
                  </ListItem>
                ))}
              </FlexCols>
            }
          </Panel>
          <PageControl size={resource?.data?.items?.length} state={state} ipp={[10, 15, 20]} defipp={10} />
        </FlexCols>
      </>
    )
  }

  function CardView() {
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)
    const state = {
      s: [start, setStart],
      e: [end, setEnd]
    }

    return (
      <>
        <FlexCols $g15>
          <Panel>
            {
              <FlexRows $g15 $jcsb $wr>
                {resource?.data?.items?.slice(start, end)?.map((item, i) => (
                  <RowItem $bd key={item.id} onClick={(e) => { handleItemSelect(e, start + i, item.id) }}>
                    <FlexRows $fx="1">
                      <FlexItem $fx="1.5"><ItemBlock item={item} /></FlexItem>
                      <FlexItem $asfe fx="0.5"><ChartItem item={item} /></FlexItem>
                    </FlexRows>
                    <FlexItem $fx="1"><SplitProgress progressValues={getSeverity(item)} /></FlexItem>
                  </RowItem>
                ))}
              </FlexRows>
            }
          </Panel>
          <PageControl size={resource?.data?.items?.length} state={state} ipp={[12, 24]} defipp={12} />
        </FlexCols>
      </>
    )
  }

  function SplitView() {
    return (
      <>
        <StyledRows $g5 $bd>
          <StyledMenuContainer>
            {resource?.data?.items?.map((item, i) => (
              <StyledMenuItem key={item.id} $current={isSelected(item.id)}
                onClick={(e) => { handleItemSelect(e, i, item.id) }}>
                <ItemBlock item={item} />
              </StyledMenuItem>
            ))}
          </StyledMenuContainer>
          <FlexItem $pd10 $fx="3" style={{ overflowY: "scroll" }}>
            <WorkspaceContent data={resource?.data?.items[selectedIndex]}
              states={[latestScan, selectedIndex, resource?.data?.items?.length]}
              handlers={[handleBack, clickPrev, clickNext]} />
          </FlexItem>
        </StyledRows>
      </>
    )
  }

  function handleItemSelect(e, index, id) {
    e.preventDefault()
    setSelectedIndex(index)
    setSelected(id)

    if (props?.view != 1) {
      setPagelevel(1)
    }
  }

  function handleBack() {
    setPagelevel(0)
  }

  function clickPrev(e) {
    e.preventDefault()

    if (resource?.data?.items && selectedIndex > 0) {
      var prevIdx = selectedIndex - 1
      setSelectedIndex(prevIdx)
      setSelected(resource?.data?.items[prevIdx].id)
    }
  }

  function clickNext(e) {
    e.preventDefault()

    if (resource?.data?.items && ((selectedIndex + 1) < resource?.data?.items?.length)) {
      var nextIdx = selectedIndex + 1
      setSelectedIndex(nextIdx)
      setSelected(resource?.data?.items[nextIdx].id)
    }
  }

  function isSelected(value) {
    return selected === value;
  }

  function ViewContent() {

    if (resource?.data === null || resource?.data?.items === null) {
      return <NoData />
    }

    switch (props?.view) {
    case 2:
      return (
        pagelevel == 0 ? <ListView /> : <WorkspaceContent data={resource?.data?.items[selectedIndex]}
          states={[latestScan, selectedIndex, resource?.data?.items?.length]}
          handlers={[handleBack, clickPrev, clickNext]} backbtn />
      )
    case 3:
      return (
        pagelevel == 0 ? <CardView /> : <WorkspaceContent data={resource?.data?.items[selectedIndex]}
          states={[latestScan, selectedIndex, resource?.data?.items?.length]}
          handlers={[handleBack, clickPrev, clickNext]} backbtn />
      )
    default:
      return (
        <SplitView />
      )
    }
  }

  return (
    <>
      {
        resource?.data && (
          <FlexCols $g15>
            <FlexItem>
              <DataSafeComponent>
                <TopPanel asset={assetOverviewData?.data} severity={severityData?.data} />
              </DataSafeComponent>
            </FlexItem>
            <FlexItem>
              <ViewContent />
            </FlexItem>
          </FlexCols>
        )
      }
      {
        <NoData criteria={resource?.data === null || resource?.data?.length === 0} />
      }
    </>
  )
}