import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 10px;
`

const StyledItems = styled.div`
  width: calc((95vw - var(--mx-sidebar-width) - 200px)/3);
  height: 160px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 4px;
  padding: 20px;
  // font-size: var(--sl-font-size-medium);
`

export const PaymentsSettings = () => {
  return (
    <StyledContainer>
      <StyledItems>
        <SlIcon name="check-circle" />&nbsp; &nbsp; &nbsp;<strong>Payments</strong> not supported or enabled for this environment.
      </StyledItems>
    </StyledContainer>
  )
}