import styled from "styled-components"
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

const StyledProgressContainer = styled.span`
  width: 100%;
  background-color: var(--sl-color-neutral-100);
  border-radius: 4px;
  display: flex;
  height: 6px;
  outline: 1px solid #0000;
  overflow: hidden;
`
const StyledProgressItem = styled.span`
  background-color: ${props => props.$color};
  width: ${props => props.$width};
  &:not(:first-child) {
    border-left: 1px solid white;
  }
`

const StyledUnorderedList = styled.ul`
  padding: 0px 0px 10px 0px;
  list-style: none !important;
`
const StyledListItem = styled.li`
  display: inline !important;
  vertical-align: baseline;
  padding: 0 4px;
  font-size: 10px;
`

const progressColors = [
  "#aa281e",
  "#dc5f23",
  "#f0963c",
  "#facd41",
  // "#c0c0c0"
]

const progressLabels = [
  "Critical",
  "High",
  "Medium",
  "Low",
  // "Info"
]

export const SplitProgress = ({ progressValues }) => {
  return (
    <>
      <StyledUnorderedList>
        {[...Array(4).keys()].map((index) => (
          <StyledListItem key={index}>
            <span style={{ verticalAlign: "middle"}}>
              <svg fill={progressColors[index]} aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16">
                <path d="M8 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"></path>
              </svg>
            </span>{progressLabels[index]} {progressValues[index] + "%"}
          </StyledListItem>
        ))}
      </StyledUnorderedList>
      <StyledProgressContainer>
        {[...Array(4).keys()].map((index) => (
          <SlTooltip content={progressLabels[index]} key={index}>
            <StyledProgressItem $color={progressColors[index]} $width={progressValues[index] + "%"} />
          </SlTooltip>
        ))}
      </StyledProgressContainer>
    </>
  )
}
