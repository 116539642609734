import styled from 'styled-components';

const StyledNavigationMenu = styled.nav`
  grid-area: nav;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: calc(var(--mx-navmenu-height) - 4px);
  width: 100vw;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  box-shadow: ${({ theme }) => theme.color.navBoxShadow};
`

export function NavigationMenu({ children }) {
  return (
    <StyledNavigationMenu>
      <StyledContainer>{children}</StyledContainer>
    </StyledNavigationMenu>
  )
}
