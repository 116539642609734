import styled from 'styled-components';

const StyledPage = styled.main`
  grid-area: page;
  background: ${({ theme }) => theme.color.background};
  padding: 0px calc(6%);
`;

export function Page({ children }) {
  return <StyledPage>{children}</StyledPage>
}
