
const defaultState = {
  d: null,  // data
  e: null,  // error
}

export const CreateSlice2 = (name, items) => {
  // eslint-disable-next-line no-unused-vars
  return (set, get) => {
    var innerStateObject = {}
    var innerFuncObject = {}

    items.forEach(item => {
      const objItem = { [item["name"]]: { ...defaultState } }
      innerStateObject = { ...innerStateObject, ...objItem }

      const funcItem = {
        ["http_req_" + name + "_" + item["name"]]: () => {
          return item["http_req"]
        },
        ["update_" + name + "_" + item["name"]]: (data) => set((state) => ({
          [name]: { ...state[name], [item["name"]]: { ...defaultState, d: data } }
        }))
      }
      innerFuncObject = { ...innerFuncObject, ...funcItem }
    });

    const rootObject = {
      [name]: { ...innerStateObject },
      ...innerFuncObject
    }
    return rootObject
  }
}