import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';

export const StyledTabs = ({ items }) => {
  return (
    <SlTabGroup>
      {items.map((item, i) => (
        <SlTab slot="nav" panel={item.id} key={i}>{item.name}</SlTab>
      ))}
      {items.map((item, i) => (
        <SlTabPanel name={item.id} key={i}>{item.children}</SlTabPanel>
      ))}
    </SlTabGroup>
  )
}
