export const Light = {
  name: "sl-theme-light",
  color: {
    background: "hsl(0, 0%, 100%)",
    text: "hsl(240, 6%, 10%)",
    border: "hsl(240, 6%, 90%)",
    selected: "hsl(200, 98%, 57%)",
    hoverBackground: "hsl(0, 0%, 95%)",
    // panelBoxShadow: "rgba(0, 0, 0, 0.08) 0px 0.9px 4px, rgba(0, 0, 0, 0.06) 0px 2.6px 8px, rgba(0, 0, 0, 0.05) 0px 5.7px 12px, rgba(0, 0, 0, 0.04) 0px 15px 15px",
    navBoxShadow: "hsla(0, 0%, 80%, 0.5) 0px 2px 4px 0px;",
    panelBoxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
    pannelInner: "hsl(0, 0%, 99%)",
    textFill: "hsl(0, 0%, 95%)",
  },
};

export const Dark = {
  name: "sl-theme-dark",
  color: {
    background: "hsl(240, 6%, 11%)",
    text: "hsl(240, 10%, 92%)",
    border: "hsl(240, 4%, 22%)",
    selected: "hsl(199, 98%, 57%)",
    hoverBackground: "hsl(240, 6%, 16%)",
    // panelBoxShadow: "rgba(0, 0, 0, 0.28) 0px 0.9px 4px, rgba(0, 0, 0, 0.21) 0px 2.6px 8px, rgba(0, 0, 0, 0.176) 0px 5.7px 12px, rgba(0, 0, 0, 0.14) 0px 15px 15px",
    navBoxShadow: "hsla(0, 0%, 20%, 0.5) 0px 2px 4px 0px;",
    panelBoxShadow: "rgba(0, 0, 0, 0.8) 0px 1px 4px;",
    pannelInner: "hsl(0, 0%, 9%)",
    textFill: "hsl(240, 6%, 18%)",
  },
};
