import { useState, useRef } from 'react';
import { TableView } from "lib/components/table";
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import styled from 'styled-components';

import { DataSafeComponent } from 'routes/main'

import { getWorkspaceList, createWorkspace } from 'lib/core/bera';

const columns = [
  {
    field: "name",
    display: "Workspace"
  },
  {
    field: "description",
    display: "Description"
  },
  {
    field: "targets",
    display: "Target"
  },
  {
    field: "created",
    display: "Created"
  }
];

const StyledLabel = styled.span`
  font-size: var(--sl-font-size-small);
  display: inline-block;
  padding-bottom: 5px;
`
const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
const StyledToastContainer = styled.div`
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

function WorkspaceList(props) {
  const resource = getWorkspaceList()

  return resource && (
    <TableView data={{ columns, rows: resource?.data }}>
      <SlTooltip content="Edit workspace">
        <SlButton variant="default" size="medium">
          <SlIcon slot="prefix" name="journal-text" style={{ fontSize: '1.2rem' }}></SlIcon>
        </SlButton>
      </SlTooltip>
      <SlTooltip content="Remove workspace">
        <SlButton variant="default" size="medium">
          <SlIcon slot="prefix" name="journal-minus" style={{ fontSize: '1.2rem' }}></SlIcon>
        </SlButton>
      </SlTooltip>
      <SlTooltip content="Add workspace">
        <SlButton variant="default" size="medium" onClick={(e) => { e.currentTarget.blur(); props.setOpen(true) }}>
          <SlIcon slot="prefix" name="journal-plus" style={{ fontSize: '1.2rem' }}></SlIcon>
        </SlButton>
      </SlTooltip>
    </TableView>
  )
}

export const WorkspacesSettings = () => {
  const [open, setOpen] = useState(false);
  const name = useRef(null);
  const desc = useRef(null);
  const target = useRef(null);
  const [loading, setLoading] = useState(false)

  const success = useRef(null);
  const error = useRef(null);
  const [okmsg, setOkMsg] = useState("")
  const [errmsg, setErrMsg] = useState("")

  // function handleRequestClose(event) {
  //   if (event.detail.source === 'overlay') {
  //     event.preventDefault();
  //   }
  // }

  const handleSubmit = async event => {
    setLoading(true)
    event.preventDefault();

    // eslint-disable-next-line no-unused-vars
    const response = await createWorkspace({
      "name": name.current.value,
      "description": desc.current.value,
      "targets": [
        target.current.value
      ],
      "scan_settings": {
        "scanner": {
          "modes": [
            "nuke"
          ]
        }
      }
    });

    if (response?.data?.error === false) {
      setOkMsg("Workspace '" + name.current.value + "' added successfully.")
      success.current.toast()
    } else {
      setErrMsg(response?.data?.message)
      error.current.toast()
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <StyledToastContainer>
        <SlAlert ref={success} variant="success" duration="2000" closable>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Success</strong>
          <br />
          {okmsg}
        </SlAlert>
        <SlAlert ref={error} variant="danger" duration="2000" closable>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>Error</strong>
          <br />
          {errmsg}
        </SlAlert>
      </StyledToastContainer>

      <StyledTabContainer>
        <StyledRow>
          <DataSafeComponent>
            <WorkspaceList setOpen={setOpen} />
          </DataSafeComponent>
        </StyledRow>
      </StyledTabContainer>
      <form onSubmit={handleSubmit}>
        <SlDrawer label="Add workspace" open={open} onSlAfterHide={() => setOpen(false)} style={{ '--size': '40vw' }}>
          <SlInput clearable required name="name" ref={name} >
            <StyledLabel slot='label'>Name</StyledLabel>
          </SlInput>
          <br />
          <SlInput clearable required name="desc" ref={desc} >
            <StyledLabel slot='label'>Description</StyledLabel>
          </SlInput>
          <br />
          <SlInput clearable required name="target" ref={target} >
            <StyledLabel slot='label'>Target</StyledLabel>
          </SlInput>
          <br />
          <SlButton slot="footer" variant="text" onClick={() => setOpen(false)}>Cancel</SlButton>
          <SlButton slot="footer" variant="primary" loading={loading} type='submit'>Add</SlButton>
        </SlDrawer>
      </form>
    </>
  )
}