
import useSessionData from "stores/useSessionData";
import BarChart from "lib/charts/bar";
import NoData from "lib/sx/no_data";

export const Trend = () => {
  var args = {
    slice_name: "stat",
    store_name: "trend"
  }

  var resource = useSessionData(args)

  return (
    resource && (
      resource?.data?.series && resource?.data?.categories ? (
        <BarChart series={resource?.data?.series} categories={resource?.data?.categories} groups={resource?.data?.groups} />
      ) : <NoData style={{ margin: "20px 0px" }} />
    )
  )
}
