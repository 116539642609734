import styled from 'styled-components';

const StyledSidebar = styled.div`
  grid-area: sidebar;
  border-right: 1px solid ${({ theme }) => theme.color.border};
`;

export function Sidebar({ children }) {
  return <StyledSidebar>{children}</StyledSidebar>
}
