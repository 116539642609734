import Chart from "react-apexcharts";
import { useTheme } from 'styled-components'

export default function BarChart(props) {
  const theme = useTheme()

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -15,
      style: {
        fontSize: '10px',
        colors: [theme.color.text]
      }
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: props?.categories,
      position: 'bottom',
      labels: {
        style: {
          colors: theme.color.text,
        }
      },
      group: {
        style: {
          fontSize: '10px',
          colors: theme.color.text,
        },
        groups: props?.groups
      },
    },
    yaxis: {
      show: false,
    },
  }

  return (
    <Chart options={options} series={props?.series} type="bar" height={180} width={480} />
  )
}