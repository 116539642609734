import { styled } from 'styled-components';

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;

  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
 
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const StyledPanelStats = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0%;
`

const StyledPanelTitle = styled.div`
  padding: 5px 0px;
  white-space: nowrap;
`

const StylePanelStat = styled.h1`
  padding: 5px 0px;
  font-size: var(--sl-font-size-2x-large);
  color: ${props => props.$color || "inherit"};
`
// export const PannelSmall = ({ data }) => {
export default function PannelSmall ({ data }) {
  return (
    <StyledPanelContainer>
      {data.map((item, i) => (
        <StyledPanelStats key={i}>
          <StyledPanelTitle>{item.title}</StyledPanelTitle>
          <StylePanelStat>{item.stat}</StylePanelStat>
        </StyledPanelStats>
      ))}
    </StyledPanelContainer>
  )
}
